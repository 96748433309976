import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useNavigate, useParams } from "react-router-dom";
import { COLORS, IMG_URL } from "../constants";
import { DEFAULT_IMG_NAME } from "../constants/env";
import useAuth from "../hooks/useAuth";
import { f } from "../lib/CurrencyFormatter";
import DialogModal from "./DialogModal";
import Header from "./Header";
import LoadingSpinner from "./LoadingSpinner";

function Refund() {
  const { id } = useParams();
  const { axiosInstance } = useAuth();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [tempSearch, setTempSearch] = useState("");
  const [search, setSearch] = useState("");
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [note, setNote] = useState("");
  const [totalQty, setTotalQty] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [total, setTotal] = useState(0);
  const [payment, setPayment] = useState(0);
  const [transaction, setTransaction] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [itemIdOngoing, setItemIdOngoing] = useState(0);
  const [newTransactionId, setNewTransactionId] = useState(null);
  const [oldItems, setOldItems] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("Cash");

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const searchedItems = useMemo(() => items, [items, search]);

  const processRefund = () => {
    setLoading(true);

    const data = {
      old_transaction_id: id,
      transaction_details: transactionDetails,
      old_items: oldItems,
      cart_name: "refund: " + transaction?.cart_name,
      total_qty: totalQty,
      total_discount: totalDiscount,
      total_profit: totalProfit,
      total: total,
      payment_method: paymentMethod,
      payment: payment,
      note: note,
    };

    axiosInstance
      .post("/processRefund", data)
      .then((res) => {
        console.log(res.data.res);
        if (res.data.res) {
          setNewTransactionId(res.data.res);
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSuccess(false);
      })
      .finally(() => setLoading(false));
  };

  const handleDiscountChange = (cartItemId, value) => {
    setTransactionDetails((current) =>
      current.map((item) => {
        if (item?.id == cartItemId) {
          return { ...item, discount: value };
        }

        return item;
      }),
    );
  };

  const handleAdd = (item_id) => {
    const item = items.filter((item) => item?.id == item_id)[0];
    const variant_id = selectedVariants?.some(
      (variant) => variant?.item_id == item?.id,
    )
      ? selectedVariants?.find((variant) => variant?.item_id == item?.id)
          .variant_id
      : 0;

    setTransactionDetails([
      ...transactionDetails,
      {
        id: itemIdOngoing,
        item_id: item?.id,
        variant_id: variant_id > 0 ? variant_id : null,
        item_name: item?.name,
        variant_name:
          variant_id > 0
            ? item?.variants?.find((variant) => variant?.id == variant_id).name
            : null,
        img_name:
          variant_id > 0
            ? item?.variants?.find((variant) => variant?.id == variant_id)
                .img_name
            : null,
        capital_price:
          variant_id > 0
            ? item?.variants?.find((variant) => variant?.id == variant_id)
                .capital_price
            : null,
        price:
          variant_id > 0
            ? item?.variants?.find((variant) => variant?.id == variant_id).price
            : null,
        qty: 1,
        discount: 0,
      },
    ]);

    setItemIdOngoing(itemIdOngoing + 1);
  };

  const addQty = (cartItemId) => {
    let qty = transactionDetails.filter((item) => item?.id == cartItemId)[0]
      .qty;
    qty++;

    setTransactionDetails((current) =>
      current.map((item) => {
        if (item?.id == cartItemId) {
          return { ...item, qty: qty };
        }

        return item;
      }),
    );
  };

  const minusQty = (cartItemId) => {
    let qty = transactionDetails.filter((item) => item?.id == cartItemId)[0]
      .qty;
    qty--;

    setTransactionDetails((current) =>
      current.map((item) => {
        if (item?.id == cartItemId) {
          return { ...item, qty: qty };
        }

        return item;
      }),
    );

    if (qty <= 0) {
      setTransactionDetails(
        transactionDetails.filter((item) => item?.id != cartItemId),
      );
    }
  };

  const handleSelectedVariants = (item_id, variant_id) => {
    setSelectedVariants((variants) => {
      const existSame = variants?.filter(
        (item) => item.item_id == item_id && item.variant_id == variant_id,
      );
      const filtered = variants?.filter((item) => item.item_id != item_id);

      if (existSame?.length <= 0) {
        return [
          ...filtered,
          {
            item_id: item_id,
            variant_id: variant_id,
          },
        ];
      } else {
        return [...filtered];
      }
    });
  };

  useEffect(() => {
    const total = transactionDetails.reduce(
      (total, item) => total + parseInt(item?.price) * parseInt(item?.qty),
      0,
    );
    const totalDiscount = transactionDetails.reduce(
      (total, item) => total + parseInt(item?.discount),
      0,
    );
    setTotalQty(
      transactionDetails.reduce(
        (total, item) => total + parseInt(item?.qty),
        0,
      ),
    );
    setTotal(total);
    setTotalDiscount(totalDiscount);
    setFinalPrice(total - totalDiscount);
    setTotalProfit(
      transactionDetails.reduce(
        (total, item) =>
          total +
          (parseInt(item?.price) - parseInt(item?.capital_price)) *
            parseInt(item?.qty) -
          parseInt(item?.discount),
        0,
      ),
    );
  }, [transactionDetails]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setSearch(tempSearch);
    }, 500);

    return () => clearTimeout(debounce);
  }, [tempSearch]);

  useEffect(() => {
    if (search == "") return;

    setLoading2(true);

    axiosInstance
      .post("/getItemsBySearch", { search: search })
      .then((res) => {
        if (res.data.res.items) {
          setItems(
            res.data.res.items.map((item) => ({
              ...item,
              variants: res.data.res.item_variants.filter(
                (variant) => variant.item_id == item.id,
              ),
            })),
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading2(false));
  }, [search]);

  useEffect(() => {
    setLoading(true);

    axios
      .all([
        axiosInstance.post("/getTransaction", { id: id }),
        axiosInstance.post("/getTransactionDetails", { transaction_id: id }),
      ])
      .then(
        axios.spread((res1, res2) => {
          if (res1.data.res) {
            setNote(res1.data.res?.note ? res1.data.res?.note : "");
            setTransaction(res1.data.res);
          }

          if (res2.data.res.transaction_details) {
            setItemIdOngoing(
              res2.data.res.transaction_details[0].id +
                res2.data.res.transaction_details.length,
            );
            setOldItems(res2.data.res.transaction_details);
            setTransactionDetails(res2.data.res.transaction_details);
          }
        }),
      )
      .catch(
        axios.spread((err1, err2) => {
          console.log(err1);
          console.log(err2);
        }),
      )
      .finally(() => setLoading(false));
  }, []);

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="Refund mb-20">
      <Header title="Refund" backTo={"/sales/" + id} />

      <div
        className="mx-auto flex flex-row gap-10 items-start"
        style={{ width: "90%" }}
      >
        <div className="w-1/2 border-2 border-gray-300 p-3 space-y-5 rounded-lg">
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4"
            autoComplete="none"
            placeholder="Search"
            value={tempSearch}
            onChange={(e) => setTempSearch(e.target.value)}
          />

          {loading2 ? (
            <LoadingSpinner />
          ) : (
            <div
              // hideScrollbars={false}
              className="space-y-2 select-none overflow-x-auto"
              style={{ maxHeight: "450px" }}
            >
              {searchedItems?.length <= 0 && (
                <p className="text-sm text-gray-500">Search for item.</p>
              )}

              {searchedItems?.map((item) => (
                <div
                  key={item?.id}
                  className="flex flex-row items-center border-2 rounded-lg p-3 gap-5"
                >
                  <img
                    src={
                      selectedVariants?.some(
                        (selected_variant) =>
                          selected_variant?.item_id == item?.id &&
                          item?.variants?.some(
                            (variant) =>
                              variant?.id == selected_variant?.variant_id,
                          ),
                      )
                        ? item?.variants?.find(
                            (variant) =>
                              variant?.id ==
                              selectedVariants?.find(
                                (selected_variant) =>
                                  selected_variant?.variant_id == variant?.id,
                              )?.variant_id,
                          )?.img_name.length > 0
                          ? IMG_URL +
                            item?.variants?.find(
                              (variant) =>
                                variant?.id ==
                                selectedVariants?.find(
                                  (selected_variant) =>
                                    selected_variant?.variant_id == variant?.id,
                                )?.variant_id,
                            )?.img_name
                          : IMG_URL + DEFAULT_IMG_NAME
                        : item?.img_name.length > 0
                        ? IMG_URL + item?.img_name
                        : IMG_URL + DEFAULT_IMG_NAME
                    }
                    alt=""
                    className="bg-gray-200 rounded-lg h-28 w-28 object-contain"
                  />

                  <div className="flex flex-col h-28 justify-between flex-1 overflow-x-hidden">
                    <div className="space-y-1">
                      <p className="font-bold">{item?.name}</p>

                      <ScrollContainer className="flex flex-row gap-2 flex-nowrap whitespace-nowrap">
                        {item?.variants?.map((variant) => (
                          <div
                            key={variant?.id}
                            className={`font-normal bg-gray-100 border ${
                              selectedVariants?.some(
                                (selected_variant) =>
                                  selected_variant.item_id == variant.item_id &&
                                  selected_variant.variant_id == variant.id,
                              )
                                ? "border-gray-700 bg-gray-500/25"
                                : "border-gray-400"
                            } px-2 select-none hover:border-gray-700`}
                            onClick={() => {
                              handleSelectedVariants(
                                variant.item_id,
                                variant.id,
                              );
                            }}
                          >
                            {variant?.name}
                          </div>
                        ))}
                      </ScrollContainer>
                    </div>

                    <div className="flex flex-row justify-between items-center">
                      <div className="flex flex-row gap-4 items-center">
                        <p className="font-bold">
                          {"Rp. "}
                          {selectedVariants?.some(
                            (selected_variant) =>
                              selected_variant.item_id == item?.id &&
                              item?.variants?.some(
                                (variant) =>
                                  variant.id == selected_variant.variant_id,
                              ),
                          )
                            ? f.format(
                                item?.variants?.find(
                                  (variant) =>
                                    variant.id ==
                                    selectedVariants?.find(
                                      (selected_variant) =>
                                        selected_variant.variant_id ==
                                        variant.id,
                                    )?.variant_id,
                                )?.price,
                              )
                            : f.format(item?.price)}
                        </p>
                        <p>
                          {"Stock: "}
                          {selectedVariants?.some(
                            (selected_variant) =>
                              selected_variant.item_id == item?.id &&
                              item?.variants?.some(
                                (variant) =>
                                  variant.id == selected_variant.variant_id,
                              ),
                          )
                            ? item?.variants?.find(
                                (variant) =>
                                  variant.id ==
                                  selectedVariants?.find(
                                    (selected_variant) =>
                                      selected_variant.variant_id == variant.id,
                                  )?.variant_id,
                              )?.stock
                            : item?.stock}
                        </p>
                      </div>

                      <button
                        onClick={() => handleAdd(item?.id)}
                        // disabled={loading2 ? true : false}
                      >
                        <PlusCircleIcon
                          className="h-7 w-7 cursor-pointer"
                          style={{ color: COLORS.green }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="w-1/2 flex flex-col justify-center border-2 border-gray-300 rounded-lg">
          <p className="text-center font-bold text-lg py-5">Refund</p>

          {transactionDetails?.map((item) => (
            <div key={item?.id} className="flex flex-col">
              <div className="flex flex-row gap-5 p-3">
                <img
                  src={
                    IMG_URL +
                    (item?.img_name ? item?.img_name : DEFAULT_IMG_NAME)
                  }
                  alt=""
                  className="bg-gray-200 rounded-lg h-28 w-28 object-contain"
                />

                <div className="flex flex-col justify-between flex-1">
                  <div className="flex flex-col">
                    <p className="font-bold">{item?.item_name}</p>
                    <p className="text-sm">
                      Variant: {item?.variant_name ? item?.variant_name : "-"}
                    </p>
                  </div>

                  <p className="font-bold">Rp. {f.format(item?.price)}</p>
                </div>

                <div className="flex flex-col items-center justify-center gap-1">
                  <p className="font-bold">Qty</p>

                  <input
                    type="number"
                    className="text-center h-7 w-14 rounded-full text-sm"
                    value={item?.qty}
                    min={1}
                    onChange={() => {}}
                    onWheel={(e) => e.target.blur()}
                  />

                  <div className="flex flex-row items-center">
                    <button
                      className="outline-none"
                      onClick={() => minusQty(item?.id)}
                    >
                      <MinusCircleIcon
                        className="h-7 w-7 cursor-pointer select-none"
                        style={{ color: COLORS.green }}
                      />
                    </button>

                    <button
                      className="outline-none"
                      onClick={() => addQty(item?.id)}
                    >
                      <PlusCircleIcon
                        className="h-7 w-7 cursor-pointer select-none"
                        style={{ color: COLORS.green }}
                      />
                    </button>
                  </div>
                </div>

                <div className="flex flex-col w-44 justify-center gap-2">
                  <div className="flex flex-row items-center justify-between">
                    <p className="text-sm">Subtotal</p>
                    <p className="text-sm font-bold">
                      Rp. {f.format(item?.price * item?.qty)}
                    </p>
                  </div>

                  <div className="flex flex-row items-center justify-between">
                    <p className="text-sm">Discount</p>
                    <input
                      type="number"
                      className="rounded-md text-sm h-7 w-24 text-right"
                      placeholder="0"
                      value={item?.discount}
                      onChange={(e) =>
                        handleDiscountChange(item?.id, Math.abs(e.target.value))
                      }
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>

                  <div className="flex flex-row items-center justify-between">
                    <p className="text-sm">Final</p>
                    <p className="text-sm font-bold">
                      Rp. {f.format(item?.price * item?.qty - item?.discount)}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="mx-auto border-t-2 border-t-gray-200 my-3"
                style={{ width: "95%" }}
              ></div>
            </div>
          ))}

          <div className="flex flex-row justify-between px-3 pb-3">
            <div className="flex flex-col gap-2">
              <label htmlFor="notes" className="text-sm font-bold">
                Notes
              </label>
              <textarea
                className="text-sm h-40 w-60 rounded-lg p-3 resize-none border-gray-400"
                placeholder="Leave note here..."
                value={note}
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
            </div>

            <div className="text-sm w-48 flex flex-col gap-2">
              <div className="flex flex-row justify-between items-center">
                <p>Qtys</p>
                <p>{totalQty} item(s)</p>
              </div>

              <div className="flex flex-row justify-between items-center">
                <p>Total</p>
                <p>Rp. {f.format(total)}</p>
              </div>

              <div className="space-y-3">
                <div className="flex flex-row justify-between items-center">
                  <p>Discounts</p>
                  <p>
                    {totalDiscount > 0
                      ? "-Rp. " + f.format(totalDiscount)
                      : "-"}
                  </p>
                </div>

                <div className="border-t-2 border-gray-200"></div>
              </div>

              <div className="flex flex-row justify-between items-center font-bold">
                <p>Final</p>
                <p>Rp. {f.format(finalPrice)}</p>
              </div>

              <div className="border-t-2 border-gray-200"></div>

              <div className="space-y-1">
                <p>Payment Method</p>

                <div className="space-x-2">
                  <input
                    id="cash"
                    type="radio"
                    name="payment_method"
                    value="Cash"
                    checked={paymentMethod == "Cash"}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                  <label htmlFor="cash">Cash</label>
                </div>

                <div className="space-x-2">
                  <input
                    id="non-cash"
                    type="radio"
                    name="payment_method"
                    value="Non-Cash"
                    checked={paymentMethod == "Non-Cash"}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                  <label htmlFor="non-cash">Non-Cash</label>
                </div>
              </div>

              {paymentMethod == "Cash" && (
                <>
                  <div className="border-t-2 border-gray-200"></div>

                  <div className="flex flex-row justify-between items-center font-bold">
                    <p>Payment</p>

                    <input
                      type="number"
                      className="font-normal rounded-md text-sm h-7 w-24 text-right"
                      placeholder="0"
                      value={payment}
                      onChange={(e) => setPayment(e.target.value)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>

                  <div className="flex flex-row justify-between items-center font-bold">
                    <p>Change</p>
                    <p>
                      Rp. {payment == 0 ? 0 : f.format(payment - finalPrice)}
                    </p>
                  </div>
                </>
              )}

              <button
                className="text-white focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm py-1 focus:outline-none w-full mt-3"
                style={{
                  backgroundColor: !hovered ? COLORS.green : COLORS.hover_green,
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={() => setIsOpen(true)}
              >
                Process Refund
              </button>
            </div>
          </div>
        </div>
      </div>

      <DialogModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        allowOutsideBox={isSuccess ? false : true}
        content={
          isSuccess ? (
            <div className="p-5 flex flex-col items-center justify-center space-y-5">
              <p className="text-lg">Item is refunded!</p>

              <button
                type="button"
                className="focus:outline-none text-white bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm p-3 py-2"
                style={{
                  backgroundColor: !hovered2
                    ? COLORS.green
                    : COLORS.hover_green,
                }}
                onMouseOver={() => setHovered2(true)}
                onMouseOut={() => setHovered2(false)}
                onClick={() =>
                  navigate("/sales/" + newTransactionId, { replace: true })
                }
              >
                OK
              </button>
            </div>
          ) : (
            <div className="p-5 flex flex-col items-center justify-center space-y-5">
              <p className="text-lg">Confirm refund process?</p>

              <div className="flex flex-row items-center justify-center space-x-2">
                <button
                  type="button"
                  className="focus:outline-none text-white bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm p-3 py-2"
                  style={{
                    backgroundColor: !hovered2
                      ? COLORS.green
                      : COLORS.hover_green,
                  }}
                  onMouseOver={() => setHovered2(true)}
                  onMouseOut={() => setHovered2(false)}
                  onClick={processRefund}
                >
                  Yes
                </button>

                <button
                  type="button"
                  className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm p-3 py-2"
                  onClick={() => setIsOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          )
        }
      />
    </div>
  );
}

export default Refund;
