import React from "react";
import { NavLink } from "react-router-dom";

function NotFound() {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col items-center gap-5">
        <p className="text-4xl">Page Not Found</p>

        <NavLink
          to="/carts"
          replace
          className="underline hover:text-purple-900"
        >
          <p>Go Back</p>
        </NavLink>
      </div>
    </div>
  );
}

export default NotFound;
