import { Menu, Transition } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { COLORS } from "../constants";
import useAuth from "../hooks/useAuth";

function NotificationBell() {
  const { axiosInstance } = useAuth();
  const [zeroStockItems, setZeroStockItems] = useState([]);
  const [zeroStockVariants, setZeroStockVariants] = useState([]);

  // const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // setLoading(true);
    // axiosInstance
    //   .post("/getZeroStockItems")
    //   .then((res) => {
    //     if (res.data.res.items) {
    //       setZeroStockItems(res.data.res.items);
    //     } else {
    //       setZeroStockItems([]);
    //     }
    //     if (res.data.res.variants) {
    //       setZeroStockVariants(res.data.res.variants);
    //     } else {
    //       setZeroStockVariants([]);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // .finally(() => setLoading(false))
  }, [isOpen, axiosInstance]);

  return (
    <div className="absolute top-10 right-10 text-right z-50">
      <Menu as="div" className="relative inline-block text-left">
        <div className="">
          <Menu.Button
            className="relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
            style={{ backgroundColor: COLORS.secondary }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <BellIcon className="h-5 w-5" />
            <span className="sr-only">Notifications</span>
            <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2">
              {zeroStockItems.length + zeroStockVariants.length}
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-max origin-top-right divide-y divide-gray-300 rounded-md bg-slate-50 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none h-[500px] overflow-y-scroll">
            {zeroStockVariants?.map((item) => (
              <div key={item?.id} className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <NavLink
                      to={
                        "/items/" + item?.item?.id + "/edit-variant/" + item?.id
                      }
                      className={`${
                        active ? "bg-violet-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <span>
                        Variant: <span className="font-bold">{item?.name}</span>{" "}
                        of <span className="font-bold">{item?.item?.name}</span>{" "}
                        is out of stock.
                      </span>
                    </NavLink>
                  )}
                </Menu.Item>
              </div>
            ))}
            {zeroStockItems?.map((item) => (
              <div key={item?.id} className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <NavLink
                      to={"/items/" + item?.id}
                      className={`${
                        active ? "bg-violet-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <span>
                        Item: <span className="font-bold">{item?.name}</span> is
                        out of stock.
                      </span>
                    </NavLink>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default NotificationBell;
