import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Carts from "./components/Carts";
import Pin from "./components/Pin";
import Layout from "./components/Layout";
import Items from "./components/Items";
import Sales from "./components/Sales";
import RequireAuth from "./components/RequireAuth";
import AddItem from "./components/AddItem";
import ViewItem from "./components/ViewItem";
import AddVariant from "./components/AddVariant";
import EditItem from "./components/EditItem";
import EditVariant from "./components/EditVariant";
import Cart from "./components/Cart";
import TransactionDetail from "./components/TransactionDetail";
import NotFound from "./components/NotFound";
import Categories from "./components/Categories";
import Refund from "./components/Refund";
import UsageLog from "./components/UsageLog";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="pin" element={<Pin />} />

        <Route element={<RequireAuth />}>
          <Route path="/" element={<Carts />} />

          <Route path="/carts">
            <Route index element={<Carts />} />
            <Route path=":id" element={<Cart />} />
          </Route>

          <Route path="/items">
            <Route index element={<Items />} />
            <Route path="add" element={<AddItem />} />
            <Route path=":id" element={<ViewItem />} />
            <Route path=":id/edit" element={<EditItem />} />
            <Route path=":id/add-variant" element={<AddVariant />} />
            <Route
              path=":id/edit-variant/:variant_id"
              element={<EditVariant />}
            />
          </Route>

          <Route path="/categories" element={<Categories />} />

          <Route path="/sales">
            <Route index element={<Sales />} />
            <Route path=":id" element={<TransactionDetail />} />
            <Route path=":id/refund" element={<Refund />} />
          </Route>

          <Route path="/usage-log" element={<UsageLog />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
