import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { COLORS } from "../constants";

function Header({ title, backTo = null, show = true }) {
  const [hovered, setHovered] = useState(false);

  return (
    <>
      <Helmet>
        <title>Stockera | {title}</title>
      </Helmet>

      {show && (
        <div className="p-10 ml-10 flex flex-row items-center gap-5">
          {backTo && (
            <NavLink
              to={backTo}
              replace
              className="p-2 rounded-full cursor-pointer"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              style={{
                backgroundColor: !hovered ? COLORS.secondary : "#280955",
              }}
            >
              <ArrowUturnLeftIcon className="h-4 w-4 text-white" />
            </NavLink>
          )}

          <p className="text-4xl font-bold">{title}</p>
        </div>
      )}
    </>
  );
}

export default Header;
