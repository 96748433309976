import { PencilSquareIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { COLORS } from "../constants";
import useAuth from "../hooks/useAuth";
import DialogModal from "./DialogModal";
import Header from "./Header";
import LoadingSpinner from "./LoadingSpinner";

function Categories() {
  const { axiosInstance } = useAuth();
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openEditCategory, setOpenEditCategory] = useState(false);

  const handleAdd = () => {
    if (!name) {
      return;
    }

    setLoading2(true);

    const data = { name: name };

    axiosInstance
      .post("/addCategory", data)
      .then((res) => {
        if (res.data.res) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
          setShowError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSuccess(false);
        setShowError(true);
      })
      .finally(() => setLoading2(false));
  };

  const handleEdit = () => {
    setLoading2(true);

    const data = { id: editId, name: editName };

    axiosInstance
      .post("/editCategory", data)
      .then((res) => {
        if (res.data.res) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
          setShowError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSuccess(false);
        setShowError(true);
      })
      .finally(() => setLoading2(false));
  };

  useEffect(() => {
    setName("");
    setShowError(false);

    if (openAddCategory || openEditCategory || !isSuccess) {
      return;
    }

    const afterOk = async () => {
      setOpenAddCategory(false);
      setOpenEditCategory(false);
    };
    afterOk().finally(() =>
      setTimeout(() => {
        setIsSuccess(false);
      }, 500),
    );
  }, [openAddCategory, openEditCategory]);

  useEffect(() => {
    if (isSuccess) {
      return;
    }

    if (!loading2) {
      setLoading(true);
    }

    axiosInstance
      .post("/getCategories")
      .then((res) => {
        if (res.data.res) {
          setCategories(res.data.res);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [isSuccess]);

  return !loading ? (
    <div className="Categories mb-10">
      <Header title="Categories" />

      <div className="mx-auto w-[70%] select-none">
        <div className="flex flex-row gap-5 flex-wrap justify-center">
          {categories?.map((item) => (
            <div
              key={item?.id}
              className="h-40 w-60 rounded-lg text-white bg-gradient-to-r from-blue-700 via-blue-800 to-blue-900 hover:bg-gradient-to-br text-center flex flex-col items-center justify-center gap-3"
              // className="h-40 w-60 flex items-center rounded-lg justify-center flex-col space-y-3 text-white"
              // style={{ backgroundColor: COLORS.secondary }}
            >
              <p className="text-xl font-bold w-5/6">{item?.name}</p>

              <button
                className="bg-white/20 hover:bg-white/30 backdrop-blur-xl px-3 rounded-md"
                onClick={() => {
                  setEditId(item?.id);
                  setEditName(item?.name);
                  setOpenEditCategory(true);
                }}
              >
                Edit
              </button>
            </div>
          ))}

          <div
            className="h-40 w-60 rounded-lg text-white bg-gradient-to-r from-orange-700 via-orange-600 to-orange-500 hover:bg-gradient-to-br text-center flex flex-col items-center justify-center gap-2 cursor-pointer"
            onClick={() => setOpenAddCategory(true)}
          >
            <p className="text-xl font-bold">Add Category</p>
            <PencilSquareIcon className="w-7 h-7" />
          </div>
        </div>
      </div>

      <DialogModal
        isOpen={openAddCategory}
        setIsOpen={setOpenAddCategory}
        allowOutsideBox={true}
        content={
          loading2 ? (
            <LoadingSpinner for_modal={true} />
          ) : !isSuccess ? (
            <div className="flex flex-col items-center justify-center gap-2">
              <input
                type="text"
                className="rounded-lg border-2 border-gray-300"
                placeholder="Category Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    handleAdd();
                  }
                }}
              />

              {showError && (
                <p className="text-red-600">Failed, please try again!</p>
              )}

              <button
                className="w-full text-white rounded-md py-1"
                style={{ backgroundColor: COLORS.green }}
                onClick={handleAdd}
              >
                Add
              </button>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center gap-5">
              <p className="text-xl font-bold">Category Added</p>

              <button
                className="w-full text-white rounded-md py-1"
                style={{ backgroundColor: COLORS.green }}
                onClick={() => setOpenAddCategory(false)}
              >
                Ok
              </button>
            </div>
          )
        }
      />

      <DialogModal
        isOpen={openEditCategory}
        setIsOpen={setOpenEditCategory}
        allowOutsideBox={true}
        content={
          loading2 ? (
            <LoadingSpinner for_modal={true} />
          ) : !isSuccess ? (
            <div className="flex flex-col items-center justify-center gap-2">
              <p className="text-lg font-bold">Edit Category</p>

              <input
                type="text"
                className="rounded-lg border-2 border-gray-300"
                placeholder="Category Name"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    handleEdit();
                  }
                }}
              />

              {showError && (
                <p className="text-red-600">Failed, please try again!</p>
              )}

              <button
                className="w-full text-white rounded-md py-1"
                style={{ backgroundColor: COLORS.green }}
                onClick={handleEdit}
              >
                Edit
              </button>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center gap-5">
              <p className="text-xl font-bold">Category Updated</p>

              <button
                className="w-full text-white rounded-md py-1"
                style={{ backgroundColor: COLORS.green }}
                onClick={() => setOpenEditCategory(false)}
              >
                Ok
              </button>
            </div>
          )
        }
      />
    </div>
  ) : (
    <LoadingSpinner />
  );
}

export default Categories;
