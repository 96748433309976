// export const df = (date) => {
//   return new Date(date).toUTCString();
// };

export const df = (date) => {
  const dateObj = new Date(date);

  const formattedDateStr = dateObj.toLocaleString("en-US", {
    timeZone: "Asia/Jakarta",
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  });

  return formattedDateStr;
};
