/* eslint-disable eqeqeq */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  useSortBy,
  useTable,
  usePagination,
} from "react-table";
import useAuth from "../hooks/useAuth";
import ColumnFilter from "./ColumnFilter";
import Header from "./Header";
import { NavLink, useNavigate } from "react-router-dom";
import { COLORS, IMG_URL } from "../constants";
import LoadingSpinner from "./LoadingSpinner";
import { f } from "../lib/CurrencyFormatter";
import { DEFAULT_IMG_NAME } from "../constants/env";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";

function Items() {
  const { axiosInstance } = useAuth();
  const navigate = useNavigate();
  const [tempData, setTempData] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState(0);
  const [tempSearch, setTempSearch] = useState("");
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState(false);
  const [hideCapital, setHideCapital] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const pageSize = 10;
  const startIndex = (currentPage - 1) * pageSize;

  const handleSelectedVariants = (item_id, variant_id) => {
    setSelectedVariants((variants) => {
      const existSame = variants?.filter(
        (item) => item.item_id == item_id && item.variant_id == variant_id,
      );
      const filtered = variants?.filter((item) => item.item_id != item_id);

      if (existSame.length <= 0) {
        return [
          ...filtered,
          {
            item_id: item_id,
            variant_id: variant_id,
          },
        ];
      } else {
        return [...filtered];
      }
    });
  };

  // REACT TABLE SECTION
  // data to be displayed into react table
  const data = useMemo(() => [...tempData], [tempData]);

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    };
  }, []);

  // initial state to hide 'id' column
  const initialState = { hiddenColumns: ["id"] };

  // variable that contains to be displayed data
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Category",
        accessor: "category_name",
        Cell: ({ row }) => (
          <p>{row.values.category_name ? row.values.category_name : "-"}</p>
        ),
      },
      {
        Header: "Variants",
        accessor: "variants",
        Cell: ({ row }) => (
          <div
            className={`${
              row.values.variants.length > 1
                ? "overflow-x-scroll"
                : "overflow-x-hidden"
            } w-40 flex flex-row items-center gap-3`}
          >
            {row?.values?.variants?.length == 0
              ? "-"
              : row?.values?.variants?.map((item, idx) => (
                  <div
                    key={idx}
                    className={`font-normal bg-gray-100 border ${
                      selectedVariants?.some(
                        (variant) =>
                          variant.item_id == item.item_id &&
                          variant.variant_id == item.id,
                      )
                        ? "border-gray-700 bg-gray-500/25"
                        : "border-gray-400"
                    } px-2 select-none hover:border-gray-700`}
                    onClick={() => {
                      handleSelectedVariants(item.item_id, item.id);
                    }}
                  >
                    {item.name}
                  </div>
                ))}
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: "Image",
        accessor: "img_url",
        Cell: ({ row }) => (
          <img
            src={
              selectedVariants?.some(
                (item) =>
                  item.item_id == row.values.id &&
                  row.values.variants.some(
                    (variant) => variant.id == item.variant_id,
                  ),
              )
                ? row.values.variants.find(
                    (variant) =>
                      variant.id ==
                      selectedVariants?.find(
                        (item) => item.variant_id == variant.id,
                      )?.variant_id,
                  )?.img_url
                : row.values.img_url
            }
            className="object-contain h-40 w-40 bg-gray-200 rounded-lg"
            alt="img"
          />
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Capital",
        accessor: "capital_price",
        Cell: ({ row }) => (
          <p>
            {selectedVariants?.some(
              (item) =>
                item.item_id == row.values.id &&
                row.values.variants.some(
                  (variant) => variant.id == item.variant_id,
                ),
            )
              ? row.values.variants.find(
                  (variant) =>
                    variant.id ==
                    selectedVariants?.find(
                      (item) => item.variant_id == variant.id,
                    )?.variant_id,
                )?.capital_price
              : row.values.variants?.length > 0
              ? f.format(
                  Math.min(
                    ...row.values.variants?.map((item) =>
                      item.capital_price.replace(/,/g, ""),
                    ),
                  ),
                )
              : row.values.capital_price}
          </p>
        ),
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: ({ row }) => (
          <p>
            {selectedVariants?.some(
              (item) =>
                item.item_id == row.values.id &&
                row.values.variants.some(
                  (variant) => variant.id == item.variant_id,
                ),
            )
              ? row.values.variants.find(
                  (variant) =>
                    variant.id ==
                    selectedVariants?.find(
                      (item) => item.variant_id == variant.id,
                    )?.variant_id,
                )?.price
              : row.values.variants?.length > 0
              ? f.format(
                  Math.min(
                    ...row.values.variants?.map((item) =>
                      item.price.replace(/,/g, ""),
                    ),
                  ),
                )
              : row.values.price}
          </p>
        ),
      },
      {
        Header: "Stock",
        accessor: "stock",
        Cell: ({ row }) => (
          <p>
            {selectedVariants?.some(
              (item) =>
                item.item_id == row.values.id &&
                row.values.variants.some(
                  (variant) => variant.id == item.variant_id,
                ),
            )
              ? row.values.variants.find(
                  (variant) =>
                    variant.id ==
                    selectedVariants?.find(
                      (item) => item.variant_id == variant.id,
                    )?.variant_id,
                )?.stock
              : row.values.variants?.length > 0
              ? row.values.variants?.reduce(
                  (sum, item) => sum + parseInt(item.stock),
                  0,
                )
              : row.values.stock}
          </p>
        ),
      },
    ],
    [selectedVariants],
  );

  // options that feed into react table to display data
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      {
        Header: "No",
        accessor: "no",
        Cell: ({ row }) => row.original.row_number,
        disableFilters: true,
      },
      ...columns,
      {
        id: "Edit",
        Header: "Edit",
        Cell: ({ row }) => (
          // change to NavLink
          <button
            type="button"
            className="font-medium text-blue-600  hover:underline"
            onClick={() => navigate("/items/" + row.values.id)}
          >
            Edit
          </button>
        ),
        disableSortBy: true,
      },
    ]);
  };

  const {
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    setGlobalFilter,
    state,
    toggleHideColumn,
  } = useTable(
    { data, columns, initialState, defaultColumn },
    useFilters,
    useGlobalFilter,
    tableHooks,
    useSortBy,
    usePagination,
  );

  // END OF REACT TABLE SECTION

  // function to fetch item by pagination
  const fetchItemsPaginated = useCallback(async () => {
    await axiosInstance
      .post("/getItemsPaginated", {
        page: currentPage,
        page_size: pageSize,
        category_id: selectCategory > 0 ? selectCategory : null,
      })
      .then((res) => {
        if (res.data.res) {
          setTempData(
            res.data.res?.items?.map((item, idx) => ({
              ...item,
              row_number: startIndex + 1 + idx,
              img_url:
                IMG_URL +
                (item?.img_name.length > 0 ? item?.img_name : DEFAULT_IMG_NAME),
              category_name: item?.category?.name,
              capital_price: f.format(item.capital_price),
              price: f.format(item.price),
              variants: res.data.res?.item_variants
                .filter((item2) => item2.item_id == item.id)
                .map((item2) => ({
                  ...item2,
                  img_url:
                    IMG_URL +
                    (item2?.img_name.length > 0
                      ? item2?.img_name
                      : DEFAULT_IMG_NAME),
                  capital_price: f.format(item2.capital_price),
                  price: f.format(item2.price),
                })),
            })),
          );

          setTotalPages(res.data.res.total_pages);
        }
      })
      .catch((err) => console.log(err));
  }, [axiosInstance, currentPage, startIndex, selectCategory]);

  // function to fetch categories
  const fetchCategories = useCallback(async () => {
    await axiosInstance
      .post("/getCategories")
      .then((res) => {
        if (res.data.res) {
          setCategories(res.data.res);
        }
      })
      .catch((err) => console.log(err));
  }, [axiosInstance]);

  // capital price column toggle
  useEffect(() => {
    toggleHideColumn("capital_price", hideCapital);
  }, [hideCapital, toggleHideColumn]);

  // search debounce
  useEffect(() => {
    const debounce = setTimeout(() => {
      setSearch(tempSearch);
    }, 500);

    return () => clearTimeout(debounce);
  }, [tempSearch]);

  // fetch by search
  // pagination handled by react table built-in pagination
  useEffect(() => {
    if (search == "") {
      return;
    }

    setLoading(true);

    axiosInstance
      .post("/getItemsBySearch", { search: search })
      .then((res) => {
        if (res.data.res.items) {
          setTempData(
            res.data.res?.items?.map((item, idx) => ({
              ...item,
              row_number: startIndex + 1 + idx,
              img_url:
                IMG_URL +
                (item?.img_name.length > 0 ? item?.img_name : DEFAULT_IMG_NAME),
              category_name: item?.category?.name,
              capital_price: f.format(item.capital_price),
              price: f.format(item.price),
              variants: res.data.res?.item_variants
                .filter((item2) => item2.item_id == item.id)
                .map((item2) => ({
                  ...item2,
                  img_url:
                    IMG_URL +
                    (item2?.img_name.length > 0
                      ? item2?.img_name
                      : DEFAULT_IMG_NAME),
                  capital_price: f.format(item2.capital_price),
                  price: f.format(item2.price),
                })),
            })),
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [search, axiosInstance, startIndex]);

  // fetch if pagination changes
  // not fetch if in search mode
  useEffect(() => {
    if (search !== "") {
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      await fetchItemsPaginated();
    };
    fetchData().finally(() => setLoading(false));
  }, [fetchItemsPaginated, search]);

  // initial fetching
  // fetch item by pagination
  // fetch categories
  useEffect(() => {
    if (!isFirstRender) {
      return;
    }

    const fetchData = async () => {
      setLoading(true);

      await fetchItemsPaginated();
      await fetchCategories();
    };
    fetchData().finally(() => {
      setIsFirstRender(false);
      setLoading(false);
    });
  }, [fetchCategories, fetchItemsPaginated, isFirstRender]);

  return !loading ? (
    <div className="Items mb-10">
      <Header title="Items" />

      <div className="mx-auto" style={{ width: "95%" }}>
        <div className="flex items-center justify-between mb-5">
          <NavLink
            to="/items/add"
            className="text-white bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2.5 focus:outline-none"
          >
            Add Item
          </NavLink>

          <div className="flex flex-row items-center gap-3">
            <select
              className="rounded-lg border border-gray-300"
              value={selectCategory}
              onChange={(e) => {
                setCurrentPage(1);
                setSelectCategory(e.target.value);
              }}
            >
              <option value={-1}>Filter Category</option>
              <option value={0}>All</option>
              {categories?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>

            {/* <GlobalFilter
              filter={state.globalFilter}
              setFilter={setGlobalFilter}
            /> */}

            <input
              className="rounded-lg px-4 border border-gray-300"
              type="text"
              value={tempSearch}
              onChange={(e) => setTempSearch(e.target.value)}
              placeholder="Search"
            />
          </div>
        </div>

        <div className="overflow-x-auto shadow-md sm:rounded-lg mb-5">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 select-none">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      scope="col"
                      className="py-5 px-6"
                    >
                      <div className="flex items-center">
                        {column.render("Header")}
                        {column.canSort && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-1 w-3 h-3"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 320 512"
                          >
                            <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                          </svg>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}

              {/* Column Filtering */}
              {/* {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  scope="col"
                  className="pl-6 py-3"
                >
                  {column.canFilter ? column.render("Filter") : ""}
                </th>
              ))}
            </tr>
          ))} */}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page?.map((row, idx) => {
                prepareRow(row);

                return (
                  <tr
                    key={row.original.id}
                    {...row.getRowProps()}
                    className="bg-white border-b"
                  >
                    {row.cells.map((cell) => (
                      <th
                        key={cell.row.id}
                        {...cell.getCellProps()}
                        scope="row"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {cell.render("Cell")}
                      </th>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="flex justify-center items-center space-x-3">
          <input
            id="hide_capital"
            type="checkbox"
            checked={hideCapital}
            onChange={() => setHideCapital(!hideCapital)}
          />
          <label htmlFor="hide_capital" className="select-none">
            Hide Capital
          </label>

          {search ? (
            <p>
              Page <strong>{state?.pageIndex + 1}</strong> of{" "}
              {pageOptions.length}
            </p>
          ) : (
            <p>
              Page <strong>{currentPage}</strong> of {totalPages}
            </p>
          )}

          <button
            className="p-2 text-sm text-white rounded-md cursor-pointer"
            style={{ backgroundColor: COLORS.secondary }}
            // onClick={() => gotoPage(0)}
            // disabled={!canPreviousPage}
            onClick={() => (search ? gotoPage(0) : setCurrentPage(1))}
            disabled={search ? !canPreviousPage : currentPage === 1}
          >
            <ChevronDoubleLeftIcon className="h-5 w-5" />
          </button>

          <button
            className="p-2 text-sm text-white rounded-md cursor-pointer"
            style={{ backgroundColor: COLORS.secondary }}
            // onClick={previousPage}
            // disabled={!canPreviousPage}
            onClick={() =>
              search ? previousPage() : setCurrentPage((prev) => prev - 1)
            }
            disabled={search ? !canPreviousPage : currentPage === 1}
          >
            <ArrowLeftIcon className="h-5 w-5" />
          </button>

          <button
            className="p-2 text-sm text-white rounded-md cursor-pointer"
            style={{ backgroundColor: COLORS.secondary }}
            // onClick={nextPage}
            // disabled={!canNextPage}
            onClick={() =>
              search ? nextPage() : setCurrentPage((prev) => prev + 1)
            }
            disabled={search ? !canNextPage : currentPage === totalPages}
          >
            <ArrowRightIcon className="h-5 w-5" />
          </button>

          <button
            className="p-2 text-sm text-white rounded-md cursor-pointer"
            style={{ backgroundColor: COLORS.secondary }}
            // onClick={() => gotoPage(pageCount - 1)}
            // disabled={!canNextPage}
            onClick={() =>
              search ? gotoPage(pageCount - 1) : setCurrentPage(totalPages)
            }
            disabled={search ? !canNextPage : currentPage === totalPages}
          >
            <ChevronDoubleRightIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
}

export default Items;
