import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import NotificationBell from "./NotificationBell";
import Sidebar from "./Sidebar";

const RequireAuth = () => {
  const { user, loading } = useAuth();
  const location = useLocation();

  return (
    !loading && (
      <>
        {user ? (
          <>
            <Sidebar />
            <NotificationBell />
            <Outlet />
          </>
        ) : (
          <Navigate to="/pin" state={{ from: location }} replace />
        )}
      </>
    )
  );
};

export default RequireAuth;
