import React from "react";
import Form from "./Form";
import Header from "./Header";

function AddItem() {
  return (
    <div className="AddItem mb-10">
      <Header title="Add Item" />

      <Form type="add" />
    </div>
  );
}

export default AddItem;
