import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { df } from "../lib/DateFormatter";
import Header from "./Header";
import LoadingSpinner from "./LoadingSpinner";

function UsageLog() {
  const { axiosInstance } = useAuth();
  const [usageLog, setUsageLog] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    axiosInstance
      .post("/usage-log")
      .then((res) => {
        if (res.data.res) {
          setUsageLog(res.data.res);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="UsageLog">
      <Header title="Usage Log" />

      <div className="mx-20">
        <div className="relative overflow-x-auto rounded-lg w-fit">
          <table className="w-full text-sm text-left text-gray-400">
            <thead className="text-xs uppercase bg-gray-700 text-white">
              <tr>
                <th scope="col" className="px-6 py-3">
                  No
                </th>
                <th scope="col" className="px-6 py-3">
                  User ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Date and Time
                </th>
              </tr>
            </thead>
            <tbody>
              {usageLog?.map((item, idx) => (
                <tr
                  key={item.id}
                  className="border-b bg-gray-800 border-gray-700"
                >
                  <td className="px-6 py-4">{idx + 1}</td>

                  <th
                    scope="row"
                    className="px-6 py-4 font-medium whitespace-nowrap text-white"
                  >
                    {item?.user_id}
                  </th>

                  <td className="text-white px-6 py-4">
                    {df(item?.created_at)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UsageLog;
