import { CheckIcon, TrashIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import "react-indiana-drag-scroll/dist/style.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { COLORS, IMG_URL } from "../constants";
import { DEFAULT_IMG_NAME } from "../constants/env";
import useAuth from "../hooks/useAuth";
import { f } from "../lib/CurrencyFormatter";
import DialogModal from "./DialogModal";
import Header from "./Header";
import LoadingSpinner from "./LoadingSpinner";

function ViewItem() {
  const params = useParams();
  const { id } = params;
  const { axiosInstance } = useAuth();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [variants, setVariants] = useState([]);

  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteVariant, setIsOpenDeleteVariant] = useState(false);
  const [deleteVariantId, setDeleteVariantId] = useState(null);
  const [deleteVariantName, setDeleteVariantName] = useState(null);
  const [showDeleteError, setShowDeleteError] = useState(false);

  const handleDelete = () => {
    setDeleteLoading(true);

    const data = {
      id: item?.id,
    };

    axiosInstance
      .post("/deleteItem", data)
      .then((res) => {
        if (res.data.res) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
          setShowDeleteError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSuccess(false);
        setShowDeleteError(true);
      })
      .finally(() => setDeleteLoading(false));
  };

  const handleDeleteVariant = () => {
    setDeleteLoading(true);

    const data = { id: deleteVariantId };

    axiosInstance
      .post("/deleteVariant", data)
      .then((res) => {
        if (res.data.res) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
          setShowDeleteError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSuccess(false);
        setShowDeleteError(true);
      })
      .finally(() => setDeleteLoading(false));
  };

  useEffect(() => {
    setHovered(false);
    setHovered2(false);
    setShowDeleteError(false);
  }, [isOpen, isOpenDeleteVariant]);

  useEffect(() => {
    if (!deleteLoading) {
      setLoading(true);
    }

    let haveItem = false;

    axios
      .all([
        axiosInstance.post("/getItem", { id: id }),
        axiosInstance.post("/getVariants", { item_id: id }),
      ])
      .then(
        axios.spread((res1, res2) => {
          if (res1.data.res) {
            haveItem = true;
            setItem(res1.data.res);
          }

          if (res2.data.res) {
            setVariants(res2.data.res);
          }
        }),
      )
      .finally(() => {
        if (!haveItem && !isSuccess) {
          navigate("/items", { replace: true });
        }
        setLoading(false);
      });
  }, [isSuccess]);

  return !loading ? (
    <div className="ViewItem mb-10">
      <Header title="View Item" backTo="/items" />

      <div className="mx-auto space-y-7" style={{ width: "90%" }}>
        <div className="flex flex-row shadow-xl duration-300 p-5 select-none">
          <img
            className="h-60 w-60 bg-gray-200 rounded-lg object-contain"
            src={IMG_URL + (item?.img_name ? item?.img_name : DEFAULT_IMG_NAME)}
            alt=""
          />

          <div className="flex flex-col flex-1 justify-between py-3 px-7">
            <div className="">
              <p className="text-2xl font-bold">{item?.name}</p>
              <p>
                <span className="font-bold">Category:</span>{" "}
                {item?.category?.name ? item?.category?.name : "None"}
              </p>
            </div>

            <div className="space-y-1">
              <div className="flex space-x-5">
                <div className="">
                  <p className="font-bold">Capital Price</p>
                  <p>{f.format(item?.capital_price)}</p>
                </div>

                <div className="">
                  <p className="font-bold">Price</p>
                  <p>{f.format(item?.price)}</p>
                </div>
              </div>

              <div className="">
                <p className="font-bold">Stock</p>
                <p>{item?.stock}</p>
              </div>
            </div>
          </div>

          <div className="self-end space-x-2">
            <NavLink
              to={"/items/" + item?.id + "/edit"}
              className="text-white focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
              style={{
                backgroundColor: !hovered ? COLORS.green : COLORS.hover_green,
              }}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              Edit
            </NavLink>

            <button
              type="button"
              className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
              onClick={() => setIsOpen(true)}
            >
              Delete
            </button>
          </div>
        </div>

        <div className="space-y-5">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-2 items-center">
              <p className="text-xl font-bold">Variants</p>
              <span
                className="text-white text-xs font-medium px-2 py-1.5 rounded-full"
                style={{
                  backgroundColor: COLORS.secondary,
                }}
              >
                {variants ? variants.length : 0}
              </span>
            </div>

            <NavLink
              to={"/items/" + item?.id + "/add-variant"}
              className="text-white bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2 focus:outline-none"
            >
              Add Variant
            </NavLink>
          </div>

          <ScrollContainer className="flex flex-nowrap whitespace-nowrap space-x-5">
            {variants?.map((variant) => (
              <div
                key={variant?.id}
                className="flex flex-row items-center border border-gray-300 rounded-lg duration-300 p-5 select-none min-w-fit"
              >
                <img
                  className="h-20 w-20 bg-gray-200 rounded-lg object-contain"
                  src={
                    IMG_URL +
                    (variant?.img_name ? variant?.img_name : DEFAULT_IMG_NAME)
                  }
                  alt=""
                />

                <div className="flex flex-col flex-1 justify-between px-5 h-28">
                  <p className="text-sm font-bold">{variant?.name}</p>

                  <div className="space-y-1 text-xs">
                    <div className="flex space-x-5">
                      <div className="">
                        <p className="font-bold">Capital Price</p>
                        <p>{f.format(variant?.capital_price)}</p>
                      </div>

                      <div className="">
                        <p className="font-bold">Price</p>
                        <p>{f.format(variant?.price)}</p>
                      </div>
                    </div>

                    <div className="">
                      <p className="font-bold">Stock</p>
                      <p>{variant?.stock}</p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center self-end gap-1">
                  <NavLink
                    to={"/items/" + id + "/edit-variant/" + variant?.id}
                    className="text-white focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-xs text-center p-1 w-14 focus:outline-none"
                    style={{
                      backgroundColor: !hovered3
                        ? COLORS.green
                        : COLORS.hover_green,
                    }}
                    onMouseEnter={() => setHovered3(true)}
                    onMouseLeave={() => setHovered3(false)}
                  >
                    Edit
                  </NavLink>

                  <button
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-xs p-1 w-14 focus:outline-none"
                    onClick={() => {
                      setIsOpenDeleteVariant(true);
                      setDeleteVariantId(variant?.id);
                      setDeleteVariantName(variant?.name);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </ScrollContainer>
        </div>
      </div>

      <DialogModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        content={
          <div className="flex flex-col items-center justify-between space-y-5">
            {deleteLoading ? (
              <LoadingSpinner for_modal={true} />
            ) : isSuccess ? (
              <>
                <CheckIcon className="h-20 w-20 text-red-600" />

                <p className="text-xl font-bold">{item?.name} is deleted!</p>

                <button
                  type="button"
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
                  onClick={() => {
                    setIsOpen(false);
                    navigate("/items", { replace: true });
                  }}
                >
                  OK
                </button>
              </>
            ) : (
              <>
                <TrashIcon className="h-20 w-20 text-red-600" />

                <div className="flex flex-col items-center space-y-1">
                  <p className="text-xl font-bold">Delete Confirmation</p>
                  <p>
                    Are you sure you want to delete{" "}
                    <span className="font-bold">{item?.name}</span>?
                  </p>

                  {showDeleteError && (
                    <p className="text-red-600">
                      Delete failed, please try again!
                    </p>
                  )}
                </div>

                <div className="space-x-2">
                  <button
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
                    onClick={handleDelete}
                  >
                    Yes
                  </button>

                  <button
                    type="button"
                    className="text-white focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
                    style={{
                      backgroundColor: !hovered2
                        ? COLORS.green
                        : COLORS.hover_green,
                    }}
                    onMouseEnter={() => setHovered2(true)}
                    onMouseLeave={() => setHovered2(false)}
                    onClick={() => setIsOpen(false)}
                  >
                    No
                  </button>
                </div>
              </>
            )}
          </div>
        }
      />

      <DialogModal
        isOpen={isOpenDeleteVariant}
        setIsOpen={setIsOpenDeleteVariant}
        content={
          <div className="flex flex-col items-center justify-between space-y-5">
            {deleteLoading ? (
              <LoadingSpinner for_modal={true} />
            ) : isSuccess ? (
              <>
                <CheckIcon className="h-20 w-20 text-red-600" />

                <p className="text-xl font-bold">
                  {deleteVariantName} is deleted!
                </p>

                <button
                  type="button"
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
                  onClick={() => {
                    const afterOk = async () => {
                      setIsOpenDeleteVariant(false);
                    };
                    afterOk().finally(() =>
                      setTimeout(() => {
                        setIsSuccess(false);
                      }, 500),
                    );
                  }}
                >
                  OK
                </button>
              </>
            ) : (
              <>
                <TrashIcon className="h-20 w-20 text-red-600" />

                <div className="flex flex-col items-center space-y-1">
                  <p className="text-xl font-bold">Delete Confirmation</p>
                  <p>
                    Are you sure you want to delete variant{" "}
                    <span className="font-bold">{deleteVariantName}</span>?
                  </p>

                  {showDeleteError && (
                    <p className="text-red-600">
                      Delete failed, please try again!
                    </p>
                  )}
                </div>

                <div className="space-x-2">
                  <button
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
                    onClick={handleDeleteVariant}
                  >
                    Yes
                  </button>

                  <button
                    type="button"
                    className="text-white focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
                    style={{
                      backgroundColor: !hovered2
                        ? COLORS.green
                        : COLORS.hover_green,
                    }}
                    onMouseEnter={() => setHovered2(true)}
                    onMouseLeave={() => setHovered2(false)}
                    onClick={() => setIsOpenDeleteVariant(false)}
                  >
                    No
                  </button>
                </div>
              </>
            )}
          </div>
        }
      />
    </div>
  ) : (
    <LoadingSpinner />
  );
}

export default ViewItem;
