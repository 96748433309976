import React, { useCallback, useEffect, useState } from "react";
import Header from "./Header";
import { f } from "../lib/CurrencyFormatter";
import useAuth from "../hooks/useAuth";
import LoadingSpinner from "./LoadingSpinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Sales() {
  const { axiosInstance } = useAuth();
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [omzet, setOmzet] = useState(0);
  const [profit, setProfit] = useState(0);
  const [sold, setSold] = useState(0);
  const [availableMonthYear, setAvailableMonthYear] = useState([]);
  const [monthlyQty, setMonthlyQty] = useState(0);
  const [monthlyOmzet, setMonthlyOmzet] = useState(0);
  const [monthlyProfit, setMonthlyProfit] = useState(0);
  const [monthlyDiscount, setMonthlyDiscount] = useState(0);
  const [selectMonthYear, setSelectMonthYear] = useState("");
  const [date, setDate] = useState("");
  const [hideCumulative, setHideCumulative] = useState(true);

  const [loading, setLoading] = useState(false);

  // const filteredTransactions = transactions.filter((item) => {
  //   if (!date) {
  //     return item?.date.includes(selectMonthYear);
  //   } else {
  //     return item?.created_at.includes(date);
  //   }
  // });

  const showCumulative = async () => {
    setLoading(true);

    await axiosInstance
      .post("/getCumulativeOmzet")
      .then((res) => {
        if (res.data?.res) {
          setOmzet(res.data?.res?.omzet);
          setProfit(res.data?.res?.profit);
          setSold(res.data?.res?.sold);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const fetchTransactions = useCallback(async () => {
    setLoading(true);
    setHideCumulative(true);

    await axiosInstance
      .post("/getTransactionsOptimized", {
        month_year: selectMonthYear === "asdqwe" ? null : selectMonthYear,
        date: date ? date : null,
      })
      .then((res) => {
        if (res.data.res) {
          setTransactions(res.data.res?.transactions);
          setOmzet(res.data.res?.omzet);
          setProfit(res.data.res?.profit);
          setSold(res.data.res?.sold);
          setAvailableMonthYear(res.data.res?.available_month_year);

          if (!date && selectMonthYear !== "") {
            setSelectMonthYear(res.data.res?.month_year);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [axiosInstance, date, selectMonthYear]);

  useEffect(() => {
    setMonthlyQty(
      transactions.reduce(
        (total, item) => total + parseInt(item?.total_qty),
        0,
      ),
    );

    setMonthlyOmzet(
      transactions.reduce((total, item) => total + parseInt(item?.total), 0),
    );

    setMonthlyProfit(
      transactions.reduce(
        (total, item) => total + parseInt(item?.total_profit),
        0,
      ),
    );

    setMonthlyDiscount(
      transactions.reduce(
        (total, item) => total + parseInt(item?.total_discount),
        0,
      ),
    );
  }, [transactions]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  return !loading ? (
    <div className="Sales mb-10 select-none">
      <Header title="Sales" />

      <div className="flex flex-row justify-center">
        <div className="flex flex-col gap-10">
          <div className="flex flex-row justify-center gap-5">
            <div className="text-white bg-gradient-to-r from-purple-800 via-purple-700 to-purple-600 hover:bg-gradient-to-br rounded-lg text-center w-60 h-40 flex flex-col items-center justify-center gap-3">
              <p className="text-4xl font-bold">Omzet</p>
              <p className="text-lg">Rp. {f.format(omzet)}</p>
            </div>

            <div className="text-white bg-gradient-to-r from-green-500 via-green-600 to-green-700 hover:bg-gradient-to-br rounded-lg text-center w-60 h-40 flex flex-col items-center justify-center gap-3">
              <p className="text-4xl font-bold">Profit</p>
              <p className="text-lg">Rp. {f.format(profit)}</p>
            </div>

            <div className="text-white bg-gradient-to-r from-orange-500 via-orange-500 to-orange-600 hover:bg-gradient-to-br rounded-lg text-center w-60 h-40 flex flex-col items-center justify-center gap-3">
              <p className="text-4xl font-bold">Items Sold</p>
              <p className="text-lg">{sold} items</p>
            </div>
          </div>

          <button
            className="text-white bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm self-center px-6 py-2.5 focus:outline-none"
            onClick={() => {
              if (!hideCumulative) {
                fetchTransactions();
                return;
              }

              setHideCumulative(false);
              showCumulative();
            }}
          >
            {hideCumulative ? "Show Cumulative" : "Hide Cumulative"}
          </button>

          <div className="flex flex-col gap-3 border-2 rounded-lg p-3">
            <div className="flex flex-row justify-between items-center mx-3">
              <p className="font-bold text-lg">Sales</p>

              <div className="flex flex-row items-center justify-center space-x-2">
                <input
                  type="date"
                  className="outline-none border border-gray-300 rounded-lg bg-gray-50"
                  value={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                    setSelectMonthYear("asdqwe");
                  }}
                />

                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-40"
                  value={selectMonthYear}
                  onChange={(e) => {
                    setSelectMonthYear(e.target.value);
                    setDate("");
                  }}
                >
                  <option value="asdqwe">Select Month</option>
                  {availableMonthYear?.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-blue-100">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      #
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Check No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Qty Sold
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Omzet
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Profit
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Discount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {transactions?.map((item, idx) => (
                    <tr
                      key={item?.id}
                      className="bg-white border-b hover:bg-gray-100"
                      onClick={() => navigate("/sales/" + item?.id)}
                    >
                      <th className="px-6 py-4 text-gray-900">{idx + 1})</th>
                      <th className="px-6 py-4 text-gray-900">{item?.id}</th>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap flex flex-row space-x-2 items-center"
                      >
                        <p>{item?.cart_name}</p>
                        {item?.refunded === true && (
                          <span className="bg-red-700 px-2 py-1 text-white text-xs font-medium rounded">
                            Refunded
                          </span>
                        )}
                      </th>
                      <td className="px-6 py-4">{item?.date}</td>
                      <td className="px-6 py-4">{item?.total_qty}</td>
                      <td className="px-6 py-4 text-right">
                        {f.format(item?.total)}
                      </td>
                      <td className="px-6 py-4 text-right">
                        {f.format(item?.total_profit)}
                      </td>
                      <td className="px-6 py-4 text-right">
                        {f.format(item?.total_discount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="font-semibold text-gray-900">
                    <th scope="row" className="px-6 py-3 text-base" colSpan={4}>
                      Total
                    </th>
                    <td className="px-6 py-3">{f.format(monthlyQty)}</td>
                    <td className="px-6 py-3 text-right">
                      {f.format(monthlyOmzet)}
                    </td>
                    <td className="px-6 py-3 text-right">
                      {f.format(monthlyProfit)}
                    </td>
                    <td className="px-6 py-3 text-right">
                      {f.format(monthlyDiscount)}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <p className="p-3">Total Transaction: {transactions.length}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
}

export default Sales;
