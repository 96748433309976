import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { COLORS, IMG_URL } from "../constants";
import { DEFAULT_IMG_NAME } from "../constants/env";
import useAuth from "../hooks/useAuth";
import { f } from "../lib/CurrencyFormatter";
import Header from "./Header";
import LoadingSpinner from "./LoadingSpinner";
import DialogModal from "./DialogModal";
import ReactToPrint from "react-to-print";
import Receipt from "./Receipt";

function TransactionDetail() {
  const { id } = useParams();
  const { axiosInstance } = useAuth();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState({});
  const [transactionDetails, setTransactionDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const printRef = useRef();

  useEffect(() => {
    setLoading(true);

    let haveTransactionDetails = false;

    axios
      .all([
        axiosInstance.post("/getTransaction", { id: id }),
        axiosInstance.post("/getTransactionDetails", { transaction_id: id }),
      ])
      .then(
        axios.spread((res1, res2) => {
          if (res1.data.res) {
            setTransaction(res1.data.res);
          }

          if (res2.data.res) {
            haveTransactionDetails = true;
            setTransactionDetails(res2.data.res);
          }
        }),
      )
      .catch(
        axios.spread((err1, err2) => {
          console.log(err1);
          console.log(err2);
        }),
      )
      .finally(() => {
        if (!haveTransactionDetails) {
          navigate("/sales", { replace: true });
        }
        setLoading(false);
      });
  }, []);

  return !loading ? (
    <div className="TransactionDetail mb-10">
      <Header title="Transaction Detail" backTo="/sales" />

      <div className="flex flex-row justify-center">
        <div className="border-2 rounded-lg p-10">
          <div className="flex flex-row items-center justify-between px-5">
            <p className="text-xl">
              Cart: <span className="font-bold">{transaction?.cart_name}</span>
            </p>

            <button
              type="button"
              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm p-2 py-1"
              onClick={() => setIsOpen(true)}
            >
              Refund
            </button>
          </div>

          {transaction?.refunded_from && (
            <NavLink to={"/sales/" + transaction?.refunded_from} reloadDocument>
              <p className="px-5 text-sm underline text-purple-900">
                Go to before refund
              </p>
            </NavLink>
          )}

          <div className="flex flex-col space-y-3">
            <div className="self-end text-xs p-5">
              <p className="font-bold text-right">Transaction Date</p>
              <p>{transaction?.date}</p>
            </div>

            {transactionDetails?.transaction_details?.map((item) => (
              <div
                key={item?.id}
                className="flex flex-col items-center justify-center"
              >
                <div className="flex flex-row gap-5 px-5 pb-5">
                  <img
                    className="h-28 w-28 object-contain bg-gray-200 rounded-lg"
                    src={
                      IMG_URL +
                      (item?.img_name ? item?.img_name : DEFAULT_IMG_NAME)
                    }
                    alt=""
                  />

                  <div className="flex flex-col justify-between w-80">
                    <div className="">
                      <p className="font-bold">{item?.item_name}</p>
                      <p className="text-sm">
                        Variant: {item?.variant_name ? item?.variant_name : "-"}
                      </p>
                    </div>

                    <div className="text-sm">
                      <div className="flex flex-row justify-between">
                        <p>
                          <span className="font-bold">
                            Rp. {f.format(item?.price)}
                          </span>{" "}
                          x {item?.qty}
                        </p>
                        <div className="flex flex-row w-28 justify-between">
                          <p>=</p>
                          <p>Rp. {f.format(item?.subtotal)}</p>
                        </div>
                      </div>

                      <div className="flex flex-row justify-between">
                        <p>Discount</p>

                        <div className="flex flex-row w-28 justify-between">
                          <p>=</p>
                          <p>
                            {item?.discount
                              ? "-Rp. " + f.format(item?.discount)
                              : "-"}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-row justify-between">
                        <p className="font-bold">Final</p>

                        <div className="flex flex-row w-28 justify-between font-bold">
                          <p>=</p>
                          <p>Rp. {f.format(item?.subtotal - item?.discount)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="border-t-2 border-gray-200 mb-5"
                  style={{ width: "95%" }}
                ></div>
              </div>
            ))}
          </div>

          <div className="flex flex-row justify-between px-3 pb-3">
            <div className="flex flex-col gap-2">
              <label htmlFor="notes" className="text-sm font-bold">
                Note
              </label>

              <div className="text-sm h-full w-60 rounded-lg p-3 resize-none border border-gray-400 overflow-y-auto">
                {transaction?.note ? (
                  <p>{transaction?.note}</p>
                ) : (
                  <p className="text-gray-500">There is no note.</p>
                )}
              </div>
            </div>

            <div className="text-sm w-48 flex flex-col gap-2">
              <div className="flex flex-row justify-between items-center">
                <p>Qtys</p>
                <p>{transactionDetails?.total_qty} item(s)</p>
              </div>

              <div className="flex flex-row justify-between items-center">
                <p>Total</p>
                <p>Rp. {f.format(transactionDetails?.total)}</p>
              </div>

              <div className="space-y-3">
                <div className="flex flex-row justify-between items-center">
                  <p>Discounts</p>
                  <p>
                    {transactionDetails?.total_discount
                      ? "-Rp. " + f.format(transactionDetails?.total_discount)
                      : "-"}
                  </p>
                </div>

                <div className="border-t-2 border-gray-200"></div>
              </div>

              <div className="flex flex-row justify-between items-center font-bold">
                <p>Final</p>
                <p>
                  Rp.{" "}
                  {f.format(
                    transactionDetails?.total -
                      transactionDetails?.total_discount,
                  )}
                </p>
              </div>

              {transaction?.payment_method == "Cash" ? (
                <>
                  <div className="flex flex-row justify-between items-center font-bold">
                    <p>Payment</p>
                    <p>Rp. {f.format(transaction?.payment)}</p>
                  </div>

                  <div className="flex flex-row justify-between items-center font-bold">
                    <p>Change</p>
                    <p>
                      Rp.{" "}
                      {f.format(
                        transaction?.payment > 0
                          ? transaction?.payment -
                              transactionDetails?.total +
                              transactionDetails?.total_discount
                          : 0,
                      )}
                    </p>
                  </div>
                </>
              ) : (
                <div className="flex flex-row items-center justify-between">
                  <p>Payment Method:</p>
                  <p>{transaction?.payment_method}</p>
                </div>
              )}

              <ReactToPrint
                trigger={() => (
                  <button
                    className="text-white focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm py-1 focus:outline-none w-full mt-3"
                    style={{
                      backgroundColor: !hovered
                        ? COLORS.green
                        : COLORS.hover_green,
                    }}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                  >
                    Print Receipt
                  </button>
                )}
                content={() => printRef.current}
              />
            </div>
          </div>
        </div>
      </div>

      <DialogModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        allowOutsideBox
        content={
          <div className="p-5 flex flex-col items-center justify-center space-y-5">
            <p className="text-lg">
              Confirm refund of cart:{" "}
              <span className="font-bold">{transaction?.cart_name}</span> ?
            </p>

            <div className="flex flex-row items-center justify-center space-x-2">
              <button
                type="button"
                className="focus:outline-none text-white bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm p-3 py-2"
                style={{
                  backgroundColor: !hovered2
                    ? COLORS.green
                    : COLORS.hover_green,
                }}
                onMouseOver={() => setHovered2(true)}
                onMouseOut={() => setHovered2(false)}
                onClick={() =>
                  navigate("/sales/" + transaction?.id + "/refund")
                }
              >
                Yes
              </button>

              <button
                type="button"
                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm p-3 py-2"
                onClick={() => setIsOpen(false)}
              >
                No
              </button>
            </div>
          </div>
        }
      />

      <div className="hidden">
        <Receipt
          ref={printRef}
          transaction={transaction}
          transactionDetails={transactionDetails?.transaction_details}
        />
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
}

export default TransactionDetail;
