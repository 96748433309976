import {
  CheckBadgeIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import React, { useEffect, useMemo, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { COLORS, IMG_URL } from "../constants";
import { f } from "../lib/CurrencyFormatter";
import Header from "./Header";
import useAuth from "../hooks/useAuth";
import LoadingSpinner from "./LoadingSpinner";
import DialogModal from "./DialogModal";
import { DEFAULT_IMG_NAME } from "../constants/env";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import Receipt from "./Receipt";
import axios from "axios";

function Cart() {
  const { axiosInstance } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [cart, setCart] = useState({});
  const [items, setItems] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [payment, setPayment] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [note, setNote] = useState("");
  const [search, setSearch] = useState("");
  const [tempSearch, setTempSearch] = useState("");
  const [totalProfit, setTotalProfit] = useState(0);
  const [transaction, setTransaction] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("Cash");

  const [addCartItemSuccess, setAddCartItemSuccess] = useState(false); // toggle
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [loading, setLoading] = useState(false); // get cart
  const [loading2, setLoading2] = useState(false); // qty, checkout, add
  const [loading3, setLoading3] = useState(false); // item list
  const [isInitital, setIsInitital] = useState(true);
  const [fromDelete, setFromDelete] = useState(false);
  const [afterAdd, setAfterAdd] = useState(false);
  const [showExist, setShowExist] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showCheckoutError, setShowCheckoutError] = useState(false);

  const searchRef = useRef();
  const printRef = useRef();
  // const btnPrintRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () =>
      navigate("/sales/" + transaction?.id, { replace: true }),
  });

  // const searchedItems = useMemo(
  //   () =>
  //     items.filter((item) =>
  //       item?.name.toLowerCase().includes(search.toLowerCase()),
  //     ),
  //   [items, search],
  // );

  const searchedItems = useMemo(() => items, [items]);

  const handleCheckout = () => {
    setLoading2(true);
    setShowCheckoutError(false);

    const data = {
      cart_id: id,
      cart_name: cart?.name,
      total_qty: totalQty,
      total: total,
      total_discount: totalDiscount,
      total_profit: totalProfit,
      payment_method: paymentMethod,
      payment: payment,
      note: note,
      items: cartItems?.map((item) => ({
        item_id: item?.item_id,
        variant_id: item?.variant_id ? item?.variant_id : null,
        item_name: item?.name,
        variant_name: item?.variant_name ? item?.variant_name : null,
        img_name: item?.img_name,
        capital_price: item?.capital_price,
        price: item?.price,
        qty: item?.qty,
        discount: item?.discount,
      })),
    };

    axiosInstance
      .post("/addTransactionDetail", data)
      .then((res) => {
        if (res.data.res) {
          console.log("addTransactionDetail: success");

          axios
            .all([
              axiosInstance.post("/getTransaction", { id: res.data.res?.id }),
              axiosInstance.post("/getTransactionDetails", {
                transaction_id: res.data.res?.id,
              }),
            ])
            .then(
              axios.spread((res1, res2) => {
                if (res1.data.res) {
                  setTransaction(res1.data.res);
                }

                if (res2.data.res) {
                  setTransactionDetails(res2.data.res);
                }

                setIsSuccess(true);
              }),
            )
            .catch(
              axios.spread((err1, err2) => {
                console.log(err1);
                console.log(err2);

                setIsSuccess(false);
              }),
            )
            .finally(() => setLoading2(false));
        } else {
          console.log("addTransactionDetail: failed");
          setIsSuccess(false);
          setShowCheckoutError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("addTransactionDetail: failed");
        setIsSuccess(false);
        setShowCheckoutError(true);
      });
  };

  const handleSelectedVariants = (item_id, variant_id) => {
    setSelectedVariants((variants) => {
      const existSame = variants?.filter(
        (item) => item.item_id == item_id && item.variant_id == variant_id,
      );
      const filtered = variants?.filter((item) => item.item_id != item_id);

      if (existSame?.length <= 0) {
        return [
          ...filtered,
          {
            item_id: item_id,
            variant_id: variant_id,
          },
        ];
      } else {
        return [...filtered];
      }
    });
  };

  const handleAdd = (item_id) => {
    setLoading2(true);

    let data = { cart_id: id, item_id: item_id, variant_id: null };

    if (selectedVariants?.find((item) => item?.item_id == item_id)) {
      data["variant_id"] = selectedVariants?.find(
        (item) => item?.item_id == item_id,
      ).variant_id;
    }

    if (
      cartItems.some(
        (item) =>
          item.item_id == data.item_id && item.variant_id == data.variant_id,
      )
    ) {
      setShowExist(true);
      setLoading2(false);
      return;
    }

    axiosInstance
      .post("/addCartItem", data)
      .then((res) => {
        if (res.data.res) {
          setAfterAdd(true);
          setAddCartItemSuccess(!addCartItemSuccess);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading2(false));
  };

  const editCartItem = () => {
    if (isInitital || fromDelete || afterAdd) {
      setIsInitital(false);
      setFromDelete(false);
      setAfterAdd(false);
      return;
    }

    axiosInstance
      .post("/editCartItem", cartItems)
      .then((res) => {
        if (res.data.res) {
          console.log("editCartItem: success");
        } else {
          console.log("editCartItem: failed");
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("editCartItem: failed");
      });
  };

  const addQty = (cartItemId) => {
    let qty = cartItems.filter((item) => item?.id == cartItemId)[0].qty;
    qty++;

    setCartItems((current) =>
      current.map((item) => {
        if (item?.id == cartItemId) {
          return { ...item, qty: qty };
        }

        return item;
      }),
    );
  };

  const minusQty = (cartItemId) => {
    let qty = cartItems.filter((item) => item?.id == cartItemId)[0].qty;
    qty--;

    if (qty <= 0) {
      setLoading2(true);

      const newCartItems = cartItems.filter((item) => item?.id != cartItemId);
      setCartItems(newCartItems);

      const data = { id: cartItemId };

      axiosInstance
        .post("/deleteCartItem", data)
        .then((res) => {
          if (res.data.res) {
            setFromDelete(true);
            console.log("deleteCartItem: success");
          } else {
            console.log("deleteCartItem: failed");
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("deleteCartItem: failed");
        })
        .finally(() => setLoading2(false));

      return;
    }

    setCartItems((current) =>
      current.map((item) => {
        if (item?.id == cartItemId) {
          if (qty <= 0) {
            return;
          }

          return { ...item, qty: qty };
        }

        return item;
      }),
    );
  };

  const handleDiscountChange = (cartItemId, value) => {
    setCartItems((current) =>
      current.map((item) => {
        if (item?.id == cartItemId) {
          return { ...item, discount: value };
        }

        return item;
      }),
    );
  };

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [loading, loading2, loading3]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setSearch(tempSearch);
    }, 500);

    return () => clearTimeout(debounce);
  }, [tempSearch]);

  useEffect(() => {
    if (isSuccess) {
      handlePrint();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (search === "") {
      return;
    }

    setLoading3(true);

    axiosInstance
      .post("/getItemsBySearch", { search: search })
      .then((res) => {
        if (res.data.res.items) {
          setItems(
            res.data.res.items.map((item) => ({
              ...item,
              variants: res.data.res.item_variants.filter(
                (variant) => variant.item_id == item.id,
              ),
            })),
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading3(false));
  }, [search, axiosInstance]);

  useEffect(() => {
    const total = cartItems.reduce(
      (total, item) => total + parseInt(item?.price) * parseInt(item?.qty),
      0,
    );
    const totalDiscount = cartItems.reduce(
      (total, item) => total + parseInt(item?.discount),
      0,
    );
    setTotalQty(
      cartItems.reduce((total, item) => total + parseInt(item?.qty), 0),
    );
    setTotal(total);
    setTotalDiscount(totalDiscount);
    setFinalPrice(total - totalDiscount);
    setTotalProfit(
      cartItems.reduce(
        (total, item) =>
          total +
          (parseInt(item?.price) - parseInt(item?.capital_price)) *
            parseInt(item?.qty) -
          parseInt(item?.discount),
        0,
      ),
    );

    const debounce = setTimeout(() => {
      editCartItem();
    }, 300);

    return () => clearTimeout(debounce);
  }, [cartItems]);

  useEffect(() => {
    const data = { cart_id: id };

    axiosInstance
      .post("/getCartItems", data)
      .then((res) => {
        if (res.data.res) {
          setCartItems(
            res.data.res.map((item) => ({
              id: item?.id,
              item_id: item?.item?.id,
              name: item?.item?.name,
              variant_id: item?.variant?.id,
              variant_name: item?.variant?.name,
              img_name: item?.variant
                ? item?.variant?.img_name
                : item?.item?.img_name,
              qty: item?.qty,
              discount: item?.discount,
              capital_price: item?.variant
                ? item?.variant?.capital_price
                : item?.item?.capital_price,
              price: item?.variant ? item?.variant?.price : item?.item?.price,
            })),
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [addCartItemSuccess, axiosInstance, id]);

  useEffect(() => {
    setHovered(false);
    setHovered2(false);
  }, [loading, loading2, loading3]);

  useEffect(() => {
    setLoading(true);

    const data = { id: id };
    let haveCart = false;

    axiosInstance
      .post("/getCart", data)
      .then((res) => {
        if (res.data.res) {
          setCart(res.data.res);
          haveCart = true;

          // setLoading3(true);

          // axiosInstance
          //   .post("/getItems")
          //   .then((res) => {
          //     if (res.data.res.items) {
          //       setItems(
          //         res.data.res.items.map((item) => ({
          //           ...item,
          //           variants: res.data.res.item_variants.filter(
          //             (variant) => variant.item_id == item.id,
          //           ),
          //         })),
          //       );
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   })
          //   .finally(() => {
          //     setLoading3(false);
          //   });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (!haveCart) {
          navigate("/carts", { replace: true });
        }
        setLoading(false);
      });
  }, [axiosInstance, id]);

  return !loading ? (
    <div className="Cart mb-20">
      <Header title={"Cart: " + cart?.name} />

      <div
        className="mx-auto flex flex-row gap-10 items-start"
        style={{ width: "90%" }}
      >
        <div className="w-1/2 border-2 border-gray-300 p-3 space-y-5 rounded-lg">
          <input
            ref={searchRef}
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4"
            autoComplete="none"
            placeholder={loading || loading2 ? "Loading..." : "Search"}
            value={tempSearch}
            disabled={loading || loading2}
            onChange={(e) => setTempSearch(e.target.value)}
          />

          {loading3 ? (
            <LoadingSpinner />
          ) : (
            <div
              // hideScrollbars={false}
              className="space-y-2 select-none overflow-x-auto"
              style={{ maxHeight: "450px" }}
            >
              {searchedItems?.length <= 0 && (
                <p className="text-sm text-gray-500">Search for item.</p>
              )}

              {searchedItems?.map((item) => (
                <div
                  key={item?.id}
                  className="flex flex-row items-center border-2 rounded-lg p-3 gap-5"
                >
                  <img
                    src={
                      selectedVariants?.some(
                        (selected_variant) =>
                          selected_variant?.item_id == item?.id &&
                          item?.variants?.some(
                            (variant) =>
                              variant?.id == selected_variant?.variant_id,
                          ),
                      )
                        ? item?.variants?.find(
                            (variant) =>
                              variant?.id ==
                              selectedVariants?.find(
                                (selected_variant) =>
                                  selected_variant?.variant_id == variant?.id,
                              )?.variant_id,
                          )?.img_name.length > 0
                          ? IMG_URL +
                            item?.variants?.find(
                              (variant) =>
                                variant?.id ==
                                selectedVariants?.find(
                                  (selected_variant) =>
                                    selected_variant?.variant_id == variant?.id,
                                )?.variant_id,
                            )?.img_name
                          : IMG_URL + DEFAULT_IMG_NAME
                        : item?.img_name.length > 0
                        ? IMG_URL + item?.img_name
                        : IMG_URL + DEFAULT_IMG_NAME
                    }
                    alt=""
                    className="bg-gray-200 rounded-lg h-28 w-28 object-contain"
                  />

                  <div className="flex flex-col h-28 justify-between flex-1 overflow-x-hidden">
                    <div className="space-y-1">
                      <p className="font-bold">{item?.name}</p>

                      <ScrollContainer className="flex flex-row gap-2 flex-nowrap whitespace-nowrap">
                        {item?.variants?.map((variant) => (
                          <div
                            key={variant?.id}
                            className={`font-normal bg-gray-100 border ${
                              selectedVariants?.some(
                                (selected_variant) =>
                                  selected_variant.item_id == variant.item_id &&
                                  selected_variant.variant_id == variant.id,
                              )
                                ? "border-gray-700 bg-gray-500/25"
                                : "border-gray-400"
                            } px-2 select-none hover:border-gray-700`}
                            onClick={() => {
                              handleSelectedVariants(
                                variant.item_id,
                                variant.id,
                              );
                            }}
                          >
                            {variant?.name}
                          </div>
                        ))}
                      </ScrollContainer>
                    </div>

                    <div className="flex flex-row justify-between items-center">
                      <div className="flex flex-row gap-4 items-center">
                        <p className="font-bold">
                          {"Rp. "}
                          {selectedVariants?.some(
                            (selected_variant) =>
                              selected_variant.item_id == item?.id &&
                              item?.variants?.some(
                                (variant) =>
                                  variant.id == selected_variant.variant_id,
                              ),
                          )
                            ? f.format(
                                item?.variants?.find(
                                  (variant) =>
                                    variant.id ==
                                    selectedVariants?.find(
                                      (selected_variant) =>
                                        selected_variant.variant_id ==
                                        variant.id,
                                    )?.variant_id,
                                )?.price,
                              )
                            : f.format(item?.price)}
                        </p>
                        <p>
                          {"Stock: "}
                          {selectedVariants?.some(
                            (selected_variant) =>
                              selected_variant.item_id == item?.id &&
                              item?.variants?.some(
                                (variant) =>
                                  variant.id == selected_variant.variant_id,
                              ),
                          )
                            ? item?.variants?.find(
                                (variant) =>
                                  variant.id ==
                                  selectedVariants?.find(
                                    (selected_variant) =>
                                      selected_variant.variant_id == variant.id,
                                  )?.variant_id,
                              )?.stock
                            : item?.stock}
                        </p>
                      </div>

                      <button
                        onClick={() => handleAdd(item?.id)}
                        disabled={loading2 ? true : false}
                      >
                        <PlusCircleIcon
                          className="h-7 w-7 cursor-pointer"
                          style={{ color: COLORS.green }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {cartItems.length > 0 && (
          <div className="w-1/2 flex flex-col justify-center border-2 border-gray-300 rounded-lg">
            <p className="text-center font-bold text-lg py-5">
              Checkout Details
            </p>

            {cartItems?.map((item) => (
              <div key={item?.id} className="flex flex-col">
                <div className="flex flex-row gap-5 p-3">
                  <img
                    src={
                      IMG_URL +
                      (item?.img_name ? item?.img_name : DEFAULT_IMG_NAME)
                    }
                    alt=""
                    className="bg-gray-200 rounded-lg h-28 w-28 object-contain"
                  />

                  <div className="flex flex-col justify-between flex-1">
                    <div className="flex flex-col">
                      <p className="font-bold">{item?.name}</p>
                      <p className="text-sm">
                        Variant: {item?.variant_name ? item?.variant_name : "-"}
                      </p>
                    </div>

                    <p className="font-bold">Rp. {f.format(item?.price)}</p>
                  </div>

                  <div className="flex flex-col items-center justify-center gap-1">
                    <p className="font-bold">Qty</p>

                    <input
                      type="number"
                      className="text-center h-7 w-14 rounded-full text-sm"
                      value={item?.qty}
                      min={1}
                      onChange={() => {}}
                      onWheel={(e) => e.target.blur()}
                    />

                    <div className="flex flex-row items-center">
                      <button
                        className="outline-none"
                        onClick={() => minusQty(item?.id)}
                        disabled={loading2 ? true : false}
                      >
                        <MinusCircleIcon
                          className="h-7 w-7 cursor-pointer select-none"
                          style={{ color: COLORS.green }}
                        />
                      </button>

                      <button
                        className="outline-none"
                        onClick={() => addQty(item?.id)}
                        disabled={loading2 ? true : false}
                      >
                        <PlusCircleIcon
                          className="h-7 w-7 cursor-pointer select-none"
                          style={{ color: COLORS.green }}
                        />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col w-44 justify-center gap-2">
                    <div className="flex flex-row items-center justify-between">
                      <p className="text-sm">Subtotal</p>
                      <p className="text-sm font-bold">
                        Rp. {f.format(item?.price * item?.qty)}
                      </p>
                    </div>

                    <div className="flex flex-row items-center justify-between">
                      <p className="text-sm">Discount</p>
                      <input
                        type="number"
                        className="rounded-md text-sm h-7 w-24 text-right"
                        placeholder="0"
                        value={item?.discount}
                        onChange={(e) =>
                          handleDiscountChange(
                            item?.id,
                            Math.abs(e.target.value),
                          )
                        }
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>

                    <div className="flex flex-row items-center justify-between">
                      <p className="text-sm">Final</p>
                      <p className="text-sm font-bold">
                        Rp. {f.format(item?.price * item?.qty - item?.discount)}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="mx-auto border-t-2 border-t-gray-200 my-3"
                  style={{ width: "95%" }}
                ></div>
              </div>
            ))}

            <div className="flex flex-row justify-between px-3 pb-3">
              <div className="flex flex-col gap-2">
                <label htmlFor="notes" className="text-sm font-bold">
                  Notes
                </label>
                <textarea
                  className="text-sm h-40 w-60 rounded-lg p-3 resize-none border-gray-400"
                  placeholder="Leave note here..."
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>

              <div className="text-sm w-48 flex flex-col gap-2">
                <div className="flex flex-row justify-between items-center">
                  <p>Qtys</p>
                  <p>{totalQty} item(s)</p>
                </div>

                <div className="flex flex-row justify-between items-center">
                  <p>Total</p>
                  <p>Rp. {f.format(total)}</p>
                </div>

                <div className="space-y-3">
                  <div className="flex flex-row justify-between items-center">
                    <p>Discounts</p>
                    <p>
                      {totalDiscount > 0
                        ? "-Rp. " + f.format(totalDiscount)
                        : "-"}
                    </p>
                  </div>

                  <div className="border-t-2 border-gray-200"></div>
                </div>

                <div className="flex flex-row justify-between items-center font-bold">
                  <p>Final</p>
                  <p>Rp. {f.format(finalPrice)}</p>
                </div>

                <div className="border-t-2 border-gray-200"></div>

                <div className="space-y-1">
                  <p>Payment Method</p>

                  <div className="space-x-2">
                    <input
                      id="cash"
                      type="radio"
                      name="payment_method"
                      value="Cash"
                      checked={paymentMethod === "Cash"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <label htmlFor="cash">Cash</label>
                  </div>

                  <div className="space-x-2">
                    <input
                      id="non-cash"
                      type="radio"
                      name="payment_method"
                      value="Non-Cash"
                      checked={paymentMethod === "Non-Cash"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <label htmlFor="non-cash">Non-Cash</label>
                  </div>
                </div>

                {paymentMethod === "Cash" && (
                  <>
                    <div className="border-t-2 border-gray-200"></div>

                    <div className="flex flex-row justify-between items-center font-bold">
                      <p>Payment</p>

                      <input
                        type="number"
                        className="font-normal rounded-md text-sm h-7 w-24 text-right"
                        placeholder="0"
                        value={payment}
                        onChange={(e) => setPayment(e.target.value)}
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>

                    <div className="flex flex-row justify-between items-center font-bold">
                      <p>Change</p>
                      <p>
                        Rp. {payment === 0 ? 0 : f.format(payment - finalPrice)}
                      </p>
                    </div>
                  </>
                )}

                <button
                  className="text-white focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm py-1 focus:outline-none w-full mt-3"
                  style={{
                    backgroundColor: !hovered
                      ? COLORS.green
                      : COLORS.hover_green,
                  }}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  onClick={() => setShowCheckout(true)}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <DialogModal
        isOpen={showExist}
        setIsOpen={setShowExist}
        allowOutsideBox={true}
        content={
          <p className="text-red-600 select-none">Can not add the same item!</p>
        }
      />

      <DialogModal
        isOpen={showCheckout}
        setIsOpen={setShowCheckout}
        content={
          loading2 ? (
            <LoadingSpinner for_modal={true} />
          ) : isSuccess ? (
            <div className="flex flex-col items-center justify-center gap-5">
              <CheckBadgeIcon
                className="h-20 w-20"
                style={{ color: COLORS.green }}
              />

              <p className="font-bold">Checkout success!</p>

              <div className="space-x-5">
                <NavLink
                  to={"/sales/" + transaction?.id}
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                >
                  View Transaction Detail
                </NavLink>

                {/* <ReactToPrint
                  trigger={() => (
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      disabled={!transaction || !transactionDetails}
                    >
                      Print Receipt
                    </button>
                  )}
                  content={() => printRef.current}
                  onAfterPrint={() =>
                    navigate("/sales/" + transaction?.id, { replace: true })
                  }
                /> */}

                <NavLink
                  to="/carts"
                  replace
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                >
                  Back to Carts
                </NavLink>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center gap-5">
              <p className="font-bold">Confirm checkout?</p>

              <div className="space-x-2">
                <button
                  type="button"
                  className="text-white focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
                  style={{
                    backgroundColor: !hovered2
                      ? COLORS.green
                      : COLORS.hover_green,
                  }}
                  onMouseEnter={() => setHovered2(true)}
                  onMouseLeave={() => setHovered2(false)}
                  onClick={handleCheckout}
                >
                  Checkout
                </button>

                <button
                  type="button"
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
                  onClick={() => setShowCheckout(false)}
                >
                  Cancel
                </button>
              </div>

              {showCheckoutError && (
                <p className="text-red-600 text-sm">
                  Checkout failed. Please try again!
                </p>
              )}
            </div>
          )
        }
      />

      <div className="hidden">
        <Receipt
          ref={printRef}
          transaction={transaction}
          transactionDetails={transactionDetails?.transaction_details}
        />
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
}

export default Cart;
