import { TrashIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Header from "./Header";
import { df } from "../lib/DateFormatter";
import LoadingSpinner from "./LoadingSpinner";
import DialogModal from "./DialogModal";
import { CheckIcon } from "@heroicons/react/24/solid";
import { COLORS } from "../constants";

function Carts() {
  const { axiosInstance } = useAuth();
  const navigate = useNavigate();
  const [carts, setCarts] = useState([]);
  const [name, setName] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState(null);
  const [latestCartId, setLatestCartId] = useState(null);

  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [isOpenCart, setIsOpenCart] = useState(false);
  const [showNameError, setShowNameError] = useState(false);

  const handleAdd = () => {
    if (!name) {
      return;
    }

    setLoading2(true);

    const data = { name: name };

    axiosInstance
      .post("/addCart", data)
      .then((res) => {
        console.log(res.data.res);
        if (res.data.res) {
          setLatestCartId(res.data.res.id);
          setIsSuccess(true);
          setShowNameError(false);
        } else {
          setShowNameError(true);
          setIsSuccess(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowNameError(true);
        setIsSuccess(false);
      })
      .finally(() => {
        setName("");
        setLoading2(false);
      });
  };

  const handleDelete = () => {
    setLoading2(true);

    const data = { id: deleteId };

    axiosInstance
      .post("/deleteCart", data)
      .then((res) => {
        if (res.data.res) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSuccess(false);
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  useEffect(() => {
    if (latestCartId) {
      navigate("/carts/" + latestCartId);
    }
  }, [latestCartId]);

  useEffect(() => {
    setHovered(false);
  }, [isOpen, isOpenCart, loading, loading2]);

  useEffect(() => {
    if (!isOpenCart && isSuccess) {
      const afterOk = async () => {
        setIsOpenCart(false);
      };
      afterOk().finally(() =>
        setTimeout(() => {
          setIsSuccess(false);
        }, 500),
      );
    }
  }, [isOpenCart]);

  useEffect(() => {
    if (!loading2) {
      setLoading(true);
    }

    axiosInstance
      .post("/getCarts")
      .then((res) => {
        if (res.data.res) {
          setCarts(res.data.res);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [loading2]);

  return !loading ? (
    <div className="Carts mb-10">
      <Header title="Carts" />

      <div className="mx-auto space-y-7" style={{ width: "80%" }}>
        <button
          className="text-white bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2.5 focus:outline-none"
          onClick={() => setIsOpenCart(true)}
        >
          Add Cart
        </button>

        <div className="space-y-3">
          {carts.length <= 0 && <p>No cart, add cart to get started.</p>}

          {carts?.map((item, idx) => (
            <div
              key={item?.id}
              className="flex flex-row justify-between items-center"
            >
              <div className="flex flex-row gap-3 items-center">
                <div className="">
                  <p className="text-lg">• </p>
                </div>
                <div className="flex flex-row items-center gap-3">
                  <NavLink
                    to={"/carts/" + item?.id}
                    className="text-lg hover:underline"
                  >
                    {item?.name}
                  </NavLink>

                  <span className="px-2 py-1 rounded-md bg-gray-200">
                    Item: {item?.item_count}
                  </span>
                </div>
              </div>

              <div className="flex flex-row gap-3 items-center">
                <p className="text-sm text-gray-500">
                  Created at: {df(item?.created_at)}
                </p>

                <div
                  className="bg-red-600 hover:bg-red-800 p-1 rounded-md cursor-pointer"
                  onClick={() => {
                    setDeleteId(item?.id);
                    setDeleteName(item?.name);
                    setIsOpen(true);
                  }}
                >
                  <TrashIcon className="w-4 h-4 text-white" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <DialogModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        content={
          <div className="flex flex-col items-center justify-between space-y-5">
            {loading2 ? (
              <LoadingSpinner />
            ) : isSuccess ? (
              <>
                <CheckIcon
                  className="h-20 w-20"
                  style={{
                    color: COLORS.green,
                  }}
                />

                <p className="text-xl font-bold">{deleteName} is deleted!</p>

                <button
                  type="button"
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
                  onClick={() => {
                    const afterOk = async () => {
                      setIsOpen(false);
                    };
                    afterOk().finally(() =>
                      setTimeout(() => {
                        setIsSuccess(false);
                      }, 500),
                    );
                  }}
                >
                  OK
                </button>
              </>
            ) : (
              <>
                <TrashIcon className="h-20 w-20 text-red-600" />

                <div className="flex flex-col items-center space-y-1">
                  <p className="text-xl font-bold">Delete Confirmation</p>
                  <p>
                    Are you sure you want to delete{" "}
                    <span className="font-bold">{deleteName}</span>?
                  </p>
                </div>

                <div className="space-x-2">
                  <button
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
                    onClick={handleDelete}
                  >
                    Yes
                  </button>

                  <button
                    type="button"
                    className="text-white focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
                    style={{
                      backgroundColor: !hovered
                        ? COLORS.green
                        : COLORS.hover_green,
                    }}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    onClick={() => setIsOpen(false)}
                  >
                    No
                  </button>
                </div>
              </>
            )}
          </div>
        }
      />

      <DialogModal
        isOpen={isOpenCart}
        setIsOpen={setIsOpenCart}
        allowOutsideBox={true}
        content={
          loading2 ? (
            <LoadingSpinner for_modal={true} />
          ) : !isSuccess ? (
            <div className="space-y-5 flex flex-col justify-center items-center mx-3 my-5">
              <p className="font-bold text-center">Add Cart</p>

              <input
                type="text"
                id="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Cart Name"
                required
                value={name}
                autoComplete="none"
                onChange={(e) => setName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    handleAdd();
                  }
                }}
              />

              <div className="space-y-2 w-full">
                <button
                  className="text-white focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm py-2 focus:outline-none w-full"
                  style={{
                    backgroundColor: !hovered
                      ? COLORS.green
                      : COLORS.hover_green,
                  }}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  onClick={handleAdd}
                  disabled={!name ? true : false}
                >
                  Add
                </button>

                {showNameError && (
                  <p className="text-center text-xs text-red-600">
                    Something is wrong.
                    <br />
                    Please try again!
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center space-y-4 mx-10">
              <CheckIcon
                className="h-20 w-20"
                style={{ color: COLORS.green }}
              />

              <p className="font-bold text-lg">Cart Added</p>

              <button
                className="text-white focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
                style={{
                  backgroundColor: !hovered ? COLORS.green : COLORS.hover_green,
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={() => {
                  const afterOk = async () => {
                    setIsOpenCart(false);
                  };
                  afterOk().finally(() => {
                    setTimeout(() => {
                      setIsSuccess(false);
                    }, 500);
                  });
                }}
              >
                Ok
              </button>
            </div>
          )
        }
      />
    </div>
  ) : (
    <LoadingSpinner />
  );
}

export default Carts;
