/* eslint-disable eqeqeq */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../constants/env";
import useAuth from "../hooks/useAuth";
import Header from "./Header";

function Pin() {
  const { user, setUser, loading } = useAuth();
  const [pin, setPin] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from?.pathname || "/";
  const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);
  const [showLoading, setShowLoading] = useState(false);
  const [wrongPin, setWrongPin] = useState(false);

  useEffect(() => {
    if (pin.length === 6) {
      setShowLoading(true);
      const url = API_URL + "/verifyPin";

      axios
        .post(url, { pin: pin })
        .then((res) => {
          const resdata = res.data.res;
          if (resdata.status === true) {
            setCookie("id", resdata.id, { maxAge: 3600 * 3, path: "/" });
            setCookie("token", resdata.token, { maxAge: 3600 * 3, path: "/" });
            setUser({ id: resdata.id, token: resdata.token });

            navigate(from, { replace: true });
          } else {
            setWrongPin(true);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setShowLoading(false);
          setPin("");
        });
    }
  }, [pin, from, navigate, setCookie, setUser]);

  return (
    !loading && (
      <>
        {!user ? (
          <div className="Pin flex items-center justify-center h-screen">
            <Header title="Pin" show={false} />

            <div className="flex flex-col items-center justify-center space-y-5">
              <p>Enter Your Pin</p>

              <input
                className="text-center outline-none bg-gray-100 py-4 w-40 rounded-xl tracking-widest"
                type="password"
                maxLength={6}
                placeholder="• • • • • •"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                disabled={showLoading ? true : false}
              />

              {showLoading && (
                <p className="animate-bounce font-bold">Loading</p>
              )}

              {!showLoading && wrongPin && (
                <p className="text-red-500">Wrong pin</p>
              )}
            </div>
          </div>
        ) : (
          <Navigate to="/" replace />
        )}
      </>
    )
  );
}

export default Pin;
