import React from "react";
import { useParams } from "react-router-dom";
import Form from "./Form";
import Header from "./Header";

function EditItem() {
  const { id } = useParams();

  return (
    <div className="EditItem mb-10">
      <Header title="Edit Item" />

      <Form type="edit" item_id={id} />
    </div>
  );
}

export default EditItem;
