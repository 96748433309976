import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

function ColumnFilter({ column }) {
  const { filterValue, setFilter } = column;
  const [value, setValue] = useState(filterValue);

  const onChange = useAsyncDebounce(() => {
    setFilter(value);
  }, 300);

  return (
    <div>
      <input
        className="border-2 py-2 px-3 w-36 rounded-lg outline-gray-500/25"
        type="text"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Search"
      />
    </div>
  );
}

export default ColumnFilter;
