import React from "react";
import { useParams } from "react-router-dom";
import Form from "./Form";
import Header from "./Header";

function AddVariant() {
  const { id } = useParams();

  return (
    <div className="AddVariant mb-10">
      <Header title="Add Variant" />

      <Form type="add_variant" item_id={id} />
    </div>
  );
}

export default AddVariant;
