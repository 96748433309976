import React, { forwardRef } from "react";
import { f } from "../lib/CurrencyFormatter";

const Receipt = forwardRef(({ transaction, transactionDetails }, ref) => {
  return (
    <>
      <div ref={ref} className="px-3 w-[245px]">
        <div className="flex flex-col items-center justify-center mb-3">
          <p className="text-xl font-bold">Juwita Fashion</p>
          <p>Jl. Dr.Sutomo No.39 Kisaran</p>
        </div>

        <p>Check No: {transaction?.id}</p>
        <p>{transaction?.date_receipt}</p>

        <hr className="my-5 border border-dashed border-black" />

        <div className="space-y-2">
          {transactionDetails?.map((item) => (
            <div key={item.id}>
              <div className="flex flex-row space-x-2">
                <p>{item?.qty}</p>
                <p>
                  {item?.item_name}{" "}
                  {item?.variant_id ? "(" + item?.variant_name + ")" : ""}
                </p>
              </div>

              <div className="flex flex-row items-center justify-between">
                <p>@{f.format(item?.price)}</p>

                <p>{f.format(item?.qty * item?.price)}</p>
              </div>
            </div>
          ))}
        </div>

        <hr className="my-5 border border-dashed border-black" />

        {transaction?.total_discount > 0 && (
          <>
            <div className="flex flex-row items-center justify-between">
              <p>Subtotal</p>
              <p>{f.format(transaction?.total)}</p>
            </div>

            <div className="flex flex-row items-center justify-between">
              <p>Discount</p>
              <p>-{f.format(transaction?.total_discount)}</p>
            </div>
          </>
        )}

        <div className="flex flex-row items-center justify-between">
          <p>Grand Total</p>
          <p>{f.format(transaction?.total - transaction?.total_discount)}</p>
        </div>

        {transaction?.payment_method == "Cash" ? (
          <>
            <div className="flex flex-row items-center justify-between">
              <p>Bayar</p>
              <p>{f.format(transaction?.payment)}</p>
            </div>

            <div className="flex flex-row items-center justify-between">
              <p>Kembali</p>
              <p>
                {f.format(
                  transaction?.payment -
                    (transaction?.total - transaction?.total_discount),
                )}
              </p>
            </div>
          </>
        ) : (
          <p>*Pembayaran Non-Tunai</p>
        )}

        <hr className="my-5 border border-dashed border-black" />

        <p>NB:</p>

        <p className="text-sm">
          • Penukaran barang maksimal 3 hari dari tanggal pembelian
        </p>
        <p className="text-sm">
          • Penukaran wajib membawa struk dan label pakaian tidak boleh
          dilepas/hilang
        </p>

        <hr className="my-5 border border-dashed border-black" />

        <p className="text-center">TERIMA KASIH</p>
        <p className="text-center">ATAS KUNJUNGAN ANDA</p>

        <hr className="mt-5 border border-dashed border-gray-100" />
      </div>
    </>
  );
});

export default Receipt;
