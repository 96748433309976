import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { API_URL } from "../constants/env";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);
  const [loading, setLoading] = useState(true);

  const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + user?.token,
    },
  });

  useEffect(() => {
    setLoading(true);
    const checkUser = async () => {
      if (cookies.id && cookies.token) {
        setUser({ id: cookies.id, token: cookies.token });
      } else {
        setUser(null);
      }
    };
    checkUser().finally(() => {
      setLoading(false);
    });
  }, [cookies.id, cookies.token]);

  return (
    <AuthContext.Provider value={{ user, setUser, axiosInstance, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
