import React from "react";
import { useParams } from "react-router-dom";
import Form from "./Form";
import Header from "./Header";

function EditVariant() {
  const { variant_id } = useParams();

  return (
    <div className="EditVariant mb-10">
      <Header title="Edit Variant" />

      <Form type="edit_variant" item_id={variant_id} />
    </div>
  );
}

export default EditVariant;
