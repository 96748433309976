import { Dialog } from "@headlessui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useAuth from "../hooks/useAuth";
import { API_URL, IMG_URL } from "../constants/env";
import { NavLink } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { f } from "../lib/CurrencyFormatter";
import DialogModal from "./DialogModal";

function Form({ type, item_id }) {
  const { user, axiosInstance } = useAuth();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [imgUrl, setImgUrl] = useState(null);
  const [img, setImg] = useState(null);
  const [name, setName] = useState("");
  const [capitalPrice, setCapitalPrice] = useState("");
  const [price, setPrice] = useState("");
  const [stock, setStock] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSubmit, setIsOpenSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [submittedId, setSubmittedId] = useState(null);

  // validation
  const [initialInputName, setInitialInputName] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [showImageError, setShowImageError] = useState(false);

  // refs
  const capitalPriceRef = useRef();
  const priceRef = useRef();

  // this is used to neutralize the currency formatter
  const onlyNumberInput = (value) => {
    value = value.replace(/[^0-9,]/g, "");

    return value;
  };

  const normalizeCapitalPriceInput = (value) => {
    // first convert the formatted string to
    // plain number.
    value = value.replace(/[$,]+/g, "");
    if (isNaN(value)) {
      value = 0;
    }
    // assign plain number to state
    setCapitalPrice(value);
    // reformat input value to currency
    value = f.format(value);

    return value;
  };

  const normalizePriceInput = (value) => {
    value = value.replace(/[$,]+/g, "");
    if (isNaN(value)) {
      value = 0;
    }
    setPrice(value);
    value = f.format(value);

    return value;
  };

  const handleSelectImage = (e) => {
    const file = e.target.files[0];
    if (
      ["image/png", "image/jpg", "image/jpeg", "image/webp"].includes(file.type)
    ) {
      setImg(file);
      setImgUrl(URL.createObjectURL(file));
      setShowImageError(false);
      return;
    }

    setImg(null);
    setImgUrl(null);
    e.target.value = null;
    setShowImageError(true);
  };

  const handleSubmit = () => {
    if (!initialInputName || showNameError) {
      setInitialInputName(true);
      return;
    }

    setLoading2(true);
    setIsOpenSubmit(true);

    const fd = new FormData();
    fd.append("name", name);
    fd.append("capital_price", capitalPrice);
    fd.append("price", price);
    fd.append("stock", stock);
    fd.append("img", img);

    if (!isNaN(category) && category != "") {
      fd.append("category_id", category);
    }

    if (["edit", "edit_variant"].includes(type)) {
      fd.append("id", item_id);
    } else if (type == "add_variant") {
      fd.append("item_id", item_id);
    }

    const options = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user?.token,
        Accept: "application/json",
      },
      body: fd,
    };

    let url = "";
    if (type == "add") {
      url = "/addItem";
    } else if (type == "edit") {
      url = "/editItem";
    } else if (type == "add_variant") {
      url = "/addVariant";
    } else if (type == "edit_variant") {
      url = "/editVariant";
    }

    // TODO: MAKE ISSUCCESS FALSE RESPONSE
    fetch(API_URL + url, options)
      .then((res) => res.json())
      .then((resp) => {
        if (resp.res) {
          if (type == "add") {
            setSubmittedId(resp.res.id);
          }

          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSuccess(false);
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  useEffect(() => {
    if (!initialInputName) return;

    if (name == "") {
      setShowNameError(true);
    } else {
      setShowNameError(false);
    }
  }, [initialInputName, name]);

  useEffect(() => {
    if (capitalPriceRef.current) {
      capitalPriceRef.current.value = f.format(capitalPrice);
    }

    if (priceRef.current) {
      priceRef.current.value = f.format(price);
    }
  }, [capitalPriceRef.current, priceRef.current]);

  useEffect(() => {
    setSubmittedId(item_id);

    if (["edit", "edit_variant"].includes(type)) {
      setInitialInputName(true);
      setLoading(true);

      const data = {
        id: item_id,
      };

      let url = "";
      if (type == "edit") {
        url = "/getItem";
      } else if (type == "edit_variant") {
        url = "/getVariant";
      }

      axiosInstance
        .post(url, data)
        .then((res) => {
          if (res.data.res) {
            const data = res.data.res;

            setName(data?.name);
            setCapitalPrice(data?.capital_price);
            setPrice(data?.price);
            setStock(data?.stock);
            setImgUrl(data?.img_name ? IMG_URL + data?.img_name : null);

            if (type == "edit") {
              setCategory(data?.category_id ? data?.category_id : "");
            }

            if (type == "edit_variant") {
              setSubmittedId(data?.item_id);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }

    axiosInstance
      .post("/getCategories")
      .then((res) => {
        if (res.data.res) {
          setCategories(res.data.res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return !loading ? (
    <>
      <div className="w-5/6 mx-auto">
        <div className="mb-6">
          <label
            htmlFor="name"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            {["add", "edit"].includes(type) && "Item Name"}
            {["add_variant", "edit_variant"].includes(type) && "Variant Name"}
          </label>
          <input
            type="text"
            id="name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
            value={name}
            onKeyDown={() => {
              if (!initialInputName) setInitialInputName(true);
            }}
            onChange={(e) => setName(e.target.value)}
            placeholder="Baju Petak"
            autoComplete="none"
            required
          />
          {showNameError && (
            <>
              <p className="mt-2 text-sm text-red-700">Invalid Name</p>
              <ul className="max-w-md list-disc list-inside text-red-700 text-sm">
                <li>Name can not be empty.</li>
              </ul>
            </>
          )}
        </div>

        <div className="mb-6">
          <label
            htmlFor="capital_price"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Capital Price
          </label>
          <input
            ref={capitalPriceRef}
            type="text"
            id="capital_price"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
            placeholder="0"
            autoComplete="off"
            onKeyDown={(e) => {
              e.target.value = onlyNumberInput(e.target.value);
            }}
            onChange={(e) => {
              e.target.value = normalizeCapitalPriceInput(e.target.value);
            }}
            required
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="price"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Price
          </label>
          <input
            ref={priceRef}
            type="text"
            id="price"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
            placeholder="0"
            autoComplete="off"
            onKeyDown={(e) => {
              e.target.value = onlyNumberInput(e.target.value);
            }}
            onChange={(e) =>
              (e.target.value = normalizePriceInput(e.target.value))
            }
            required
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="stock"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Stock
          </label>
          <input
            type="number"
            id="stock"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
            value={stock}
            placeholder="0"
            onChange={(e) => setStock(e.target.value)}
            onWheel={(e) => e.target.blur()}
            required
          />
        </div>

        {!["add_variant", "edit_variant"].includes(type) && (
          <div className="mb-3">
            <label
              htmlFor="category"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Category
            </label>
            <select
              id="category"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option>Choose a category</option>
              <option>None</option>
              {categories?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div
          className="mb-3 border-2 rounded-xl w-fit"
          onClick={() => setIsOpen(true)}
        >
          {imgUrl && <img src={imgUrl} className="h-40 w-40 object-contain" />}
        </div>

        <div className="mb-3">
          <label
            className="block mb-2 text-sm font-medium text-gray-900"
            htmlFor="img"
          >
            Upload Image
          </label>
          <input
            className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:ring-0"
            aria-describedby="img_help"
            id="img"
            type="file"
            onChange={handleSelectImage}
            accept="image/png, image/jpg, image/webp, image/jpeg"
          />
          <div className="mt-1 text-sm text-gray-500" id="img_help">
            Maximum of 1 image.
          </div>
          {showImageError && (
            <>
              <p className="mt-2 text-sm text-red-700">Invalid Image Type</p>
              <ul className="max-w-md list-disc list-inside text-red-700 text-sm">
                <li>Image has to be jpg, jpeg, or png.</li>
              </ul>
            </>
          )}
        </div>

        <button
          type="button"
          className="mt-5 text-white bg-purple-700 hover:bg-purple-900 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          onClick={handleSubmit}
        >
          {type == "add" && "Add Item"}
          {type == "edit" && "Edit Item"}
          {type == "add_variant" && "Add Variant"}
          {type == "edit_variant" && "Edit Variant"}
        </button>
      </div>

      <DialogModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        content={
          imgUrl && <img src={imgUrl} className="max-h-96 object-contain" />
        }
        allowOutsideBox={true}
      />

      <DialogModal
        isOpen={isOpenSubmit}
        setIsOpen={setIsOpenSubmit}
        content={
          loading2 ? (
            <LoadingSpinner for_modal={true} />
          ) : isSuccess ? (
            <div className="space-y-5">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {type == "add" && "Item Uploaded"}
                {type == "edit" && "Item Updated"}
                {type == "add_variant" && "Variant Added"}
                {type == "edit_variant" && "Variant Updated"}
              </Dialog.Title>
              <div className="space-y-2">
                <div className="">
                  <p className="text-sm">Details</p>
                  <p className="text-sm text-gray-500">Name: {name}</p>
                  <p className="text-sm text-gray-500">
                    Capital Price: {f.format(capitalPrice)}
                  </p>
                  <p className="text-sm text-gray-500">
                    Price: {f.format(price)}
                  </p>
                  <p className="text-sm text-gray-500">
                    Stock: {stock ? stock : 0}
                  </p>
                </div>
                <p className="text-sm text-gray-800 text-justify">
                  {type == "add" &&
                    "You can either continue to add variants or go back to items page."}
                  {["edit", "add_variant", "edit_variant"].includes(type) &&
                    "You can either continue to add variants or go back to item page."}
                </p>
              </div>

              <div className="flex justify-between">
                <NavLink
                  to={"/items/" + submittedId + "/add-variant"}
                  reloadDocument
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                >
                  Add Variant
                </NavLink>

                <NavLink
                  to={type == "add" ? "/items" : "/items/" + submittedId}
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                >
                  {type == "add" && "View Items"}
                  {["edit", "add_variant", "edit_variant"].includes(type) &&
                    "View Item"}
                </NavLink>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center space-y-5">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {type == "add" && "Upload Failed"}
                {type == "edit" && "Update Failed"}
                {type == "add_variant" && "Upload Failed"}
                {type == "edit_variant" && "Update Failed"}
              </Dialog.Title>

              <p>Please try again.</p>

              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setIsOpenSubmit(false)}
              >
                Try Again
              </button>
            </div>
          )
        }
      />
    </>
  ) : (
    <LoadingSpinner />
  );
}

export default Form;
