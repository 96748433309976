import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { COLORS } from "../constants";
import { NavLink } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import useAuth from "../hooks/useAuth";
import DialogModal from "./DialogModal";
import LoadingSpinner from "./LoadingSpinner";

function Sidebar() {
  const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);
  const [links, setLinks] = useState([
    {
      name: "Carts",
      link: "/",
    },
    {
      name: "Items",
      link: "/items",
    },
    {
      name: "Categories",
      link: "/categories",
    },
    {
      name: "Sales",
      link: "/sales",
    },
  ]);

  const { user, axiosInstance } = useAuth();
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confNewPin, setConfNewPin] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [openChangePin, setOpenChangePin] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [showConfPinError, setShowConfPinError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showOldPinError, setShowOldPinError] = useState(false);
  const [showSamePinError, setShowSamePinError] = useState(false);

  const resetValue = () => {
    setOldPin("");
    setNewPin("");
    setConfNewPin("");
  };

  const handleChange = () => {
    if (
      showOldPinError ||
      showSamePinError ||
      showConfPinError ||
      !oldPin ||
      !newPin ||
      !confNewPin
    ) {
      return;
    }

    setLoading(true);

    const data = {
      id: user?.id,
      old_pin: oldPin,
      new_pin: newPin,
      conf_new_pin: confNewPin,
    };

    axiosInstance
      .post("/changePin", data)
      .then((res) => {
        if (res.data.res) {
          removeCookie("id", { path: "/" });
          removeCookie("token", { path: "/" });
          setIsSuccess(true);
        } else {
          setShowOldPinError(true);
          resetValue();
          setIsSuccess(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowOldPinError(true);
        resetValue();
        setIsSuccess(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setHovered(false);
  }, [openChangePin, loading]);

  useEffect(() => {
    resetValue();
  }, [openChangePin]);

  useEffect(() => {
    if (showOldPinError && oldPin != "") {
      setShowOldPinError(false);
    }
  }, [oldPin]);

  useEffect(() => {
    if (newPin == "") {
      setShowSamePinError(false);
      return;
    }

    if (newPin == oldPin) {
      setShowSamePinError(true);
    } else {
      setShowSamePinError(false);
    }
  }, [oldPin, newPin]);

  useEffect(() => {
    if (confNewPin == "") {
      setShowConfPinError(false);
      return;
    }

    if (newPin != confNewPin) {
      setShowConfPinError(true);
    } else {
      setShowConfPinError(false);
    }
  }, [confNewPin]);

  return (
    <div
      className={`Sidebar fixed h-screen duration-300 z-50 ${
        isOpen ? "w-60" : "w-0"
      }`}
      style={{
        backgroundColor: COLORS.secondary,
      }}
    >
      <div
        className={`absolute -right-5 top-9 p-2 rounded-full cursor-pointer ${
          isOpen ? "" : "rotate-180"
        }`}
        style={{ backgroundColor: COLORS.green }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <ArrowLeftIcon className="h-5 w-5 text-white" />
      </div>

      <div className={`${!isOpen && "hidden"}`}>
        <p className="text-center py-10 text-white font-bold text-xl">
          {isOpen ? "Store Manager" : "S"}
        </p>

        <div
          className="text-center text-white flex flex-col justify-between"
          style={{ height: "80vh" }}
        >
          <div className="flex flex-col">
            {links?.map((item, index) => (
              <NavLink
                to={item?.link}
                reloadDocument={item?.link === "/items" ? true : false}
                key={index}
                className="bg-white/10 py-2 mx-3 my-2 rounded-lg select-none cursor-pointer hover:bg-white/50"
                onClick={() => setIsOpen(false)}
              >
                {item?.name}
              </NavLink>
            ))}
          </div>

          <div className="flex flex-col">
            <button
              className="bg-white/10 py-2 mx-3 my-2 rounded-lg select-none cursor-pointer hover:bg-white/50"
              onClick={() => setOpenChangePin(true)}
            >
              Change Pin
            </button>

            <NavLink
              to="/pin"
              reloadDocument
              className="bg-white/10 py-2 mx-3 my-2 rounded-lg select-none cursor-pointer hover:bg-white/50"
              onClick={() => {
                removeCookie("id", { path: "/" });
                removeCookie("token", { path: "/" });
              }}
            >
              Lock
            </NavLink>
          </div>
        </div>
      </div>

      <DialogModal
        isOpen={openChangePin}
        setIsOpen={setOpenChangePin}
        allowOutsideBox={true}
        content={
          loading ? (
            <LoadingSpinner for_modal={true} />
          ) : isSuccess ? (
            <div className="flex flex-col items-center justify-center gap-3 m-3 text-center">
              <p className="text-2xl">Pin Changed</p>

              <NavLink
                to="/pin"
                reloadDocument
                className="text-white font-medium rounded-lg text-sm py-2 w-full"
                style={{
                  backgroundColor: !hovered ? COLORS.green : COLORS.hover_green,
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                OK
              </NavLink>
            </div>
          ) : (
            <div className="flex flex-col justify-center w-60 items-center gap-5">
              <p className="text-2xl font-bold mb-3">Change Pin</p>

              <div className="flex flex-col gap-3">
                <div className="">
                  <input
                    type="password"
                    className="border-none bg-gray-200 rounded-lg text-center tracking-widest w-60"
                    placeholder="Old Pin"
                    maxLength={6}
                    value={oldPin}
                    onChange={(e) => setOldPin(e.target.value)}
                  />

                  {showOldPinError && (
                    <p className="text-red-600 text-center">
                      Old pin is incorrect!
                    </p>
                  )}
                </div>

                <div className="">
                  <input
                    type="password"
                    className="border-none bg-gray-200 rounded-lg text-center tracking-widest w-60"
                    placeholder="New Pin"
                    maxLength={6}
                    value={newPin}
                    onChange={(e) => setNewPin(e.target.value)}
                  />

                  {showSamePinError && (
                    <p className="text-red-600 text-center">
                      New pin is the same as old pin!
                    </p>
                  )}
                </div>

                <div className="">
                  <input
                    type="password"
                    className="border-none bg-gray-200 rounded-lg text-center tracking-widest w-60"
                    placeholder="Confirm New Pin"
                    maxLength={6}
                    value={confNewPin}
                    onChange={(e) => setConfNewPin(e.target.value)}
                  />

                  {showConfPinError && (
                    <p className="text-red-600 text-center">
                      Pin does not match!
                    </p>
                  )}
                </div>
              </div>

              <button
                className="text-white focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm py-3 focus:outline-none w-full mt-3"
                style={{
                  backgroundColor: !hovered ? COLORS.green : COLORS.hover_green,
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={handleChange}
              >
                Change
              </button>
            </div>
          )
        }
      />
    </div>
  );
}

export default Sidebar;
